import React from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ChromePicker } from 'react-color';
import CKEditor from 'ckeditor4-react';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import ItemBtns from './agrupadors-item-btns';

import configCKEditor from 'src/config/ckeditor';
import Switch from '@material-ui/core/Switch';
// import CKEditorSlider from 'src/components/shared/CKEditor/CKEditorSlider';
// import CKEditorAgrupador from 'src/components/shared/CKEditor/CKEditorAgrupador';
import EditorImage from 'src/components/shared/EditorImage';

const CKEditorConfigForItemText = {
  height: 150,
  font_names: 'Encode Sans, Arial',
  toolbar: [
    { name: 'basicstyles', items: ['Bold', 'Italic'] },
    { name: 'styles', items: ['TextColor', 'FontSize', 'Font'] },
    {
      name: 'alignment',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
    },
    { name: 'links', items: ['Link', 'Unlink'] },
    {
      name: 'editing',
      items: [
        'Find',
        'Replace',
        'SelectAll',
        '-',
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
        '-',
        'Undo',
        'Redo'
      ],
    },
    { name: 'insert', items: ['CustomHorizontalLine'] },
    { name: 'source', items: ['Source'] }
  ]
};

const SubformItemsSliderItem = ({
  index,
  touched,
  errors,
  values,
  swap,
  remove,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched
}) => {
  const textErrorShouldShow = Boolean(
    touched.item &&
      errors.item &&
      touched.item[index]?.text &&
      errors.item[index]?.text
  );
  const divURLErrorShouldShow = Boolean(
    touched.item &&
      errors.item &&
      touched.item[index]?.divURL &&
      errors.item[index]?.divURL
  );
  const iconURLErrorShouldShow = Boolean(
    touched.item &&
      errors.item &&
      touched.item[index]?.iconURL &&
      errors.item[index]?.iconURL
  );

  return (
    <Grid container key={index}>
      <Grid item xs={12} md={6} className="px-3 py-3 my-2 border">
        <div>
          <h6>ITEM {index + 1}</h6>
          <ItemBtns index={index} swap={swap} remove={remove} values={values} />
        </div>

        {/* ▼ TITULO DEL ITEM */}
        <Typography color="textPrimary" variant="overline">
          Título
        </Typography>
        {/* <CKEditorSlider
          d={`item.${index}.text`}
          value={values.title}
          name="title"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        /> */}
        {/* <CKEditorAgrupador
          id={`item.${index}.text`}
          value={values.item[index].text}
          name="title"
          onChange={e => {
            setTimeout(() => {
              setFieldValue(
                `item.${index}.text`,
                e.editor.editable().getData()
              );
            }, 0);
          }}
          onBlur={e => {
            setFieldTouched(`item.${index}.text`);
          }}
          onError={e => showDeveloperMessageConsole('Error: ', e)}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        /> */}

        <CKEditor
          id={`item.${index}.titulo`}
          name={`item.${index}.titulo`}
          // initData={values.item[index].titulo} NOTA: data prop esta deprecada!
          data={values.item[index].titulo}
          config={{ ...configCKEditor, ...CKEditorConfigForItemText }}
          onNamespaceLoaded={CKEDITOR => {
            CKEDITOR.plugins.addExternal(
              'simage',
              '/ckeditor/ckeditor4-simage/',
              'plugin.js'
            );
          }}
          onInstanceReady={e => {}}
          onChange={e => {
            setTimeout(() => {
              setFieldValue(
                `item.${index}.titulo`,
                e.editor.editable().getData()
              );
            }, 0);
          }}
          onBlur={e => {
            setFieldTouched(`item.${index}.titulo`);
          }}
          onError={e => showDeveloperMessageConsole('Error: ', e)}
        />
        <FormHelperText className="text-danger">
          {textErrorShouldShow && errors.item[index]?.titulo}
        </FormHelperText>

        {/* ▼ COPETE para el contenedor del item. */}
        <Typography color="textPrimary" variant="overline">
          Copete
        </Typography>
        <CKEditor
          id={`item.${index}.copete`}
          name={`item.${index}.copete`}
          // initData={values.item[index].copete} NOTA: data prop esta deprecada!
          data={values.item[index].copete}
          config={{ ...configCKEditor, ...CKEditorConfigForItemText }}
          onNamespaceLoaded={CKEDITOR => {
            CKEDITOR.plugins.addExternal(
              'simage',
              '/ckeditor/ckeditor4-simage/',
              'plugin.js'
            );
          }}
          onInstanceReady={e => {}}
          onChange={e => {
            setTimeout(() => {
              setFieldValue(
                `item.${index}.copete`,
                e.editor.editable().getData()
              );
            }, 0);
          }}
          onBlur={e => {
            setFieldTouched(`item.${index}.copete`);
          }}
          onError={e => showDeveloperMessageConsole('Error: ', e)}
        />
        <FormHelperText className="text-danger">
          {textErrorShouldShow && errors.item[index]?.copete}
        </FormHelperText>

        {/* ▼ ORDEN para el contenedor del item. */}
        <TextField
          // error={divURLErrorShouldShow}
          fullWidth
          // helperText={divURLErrorShouldShow && errors.item[index]?.orden}
          label="Orden"
          margin="normal"
          name={`item.${index}.orden`}
          onBlur={handleBlur}
          onChange={e => {
            setFieldValue(e.target.name, e.target.value.trim());
          }}
          type="text"
          value={index + 1}
          variant="outlined"
          disabled
        />

        {/* ▼ ENLACE para el contenedor del item. */}
        <TextField
          error={divURLErrorShouldShow}
          fullWidth
          helperText={divURLErrorShouldShow && errors.item[index]?.enlace}
          label="Enlace para contenedor del item."
          margin="normal"
          name={`item.${index}.enlace`}
          onBlur={handleBlur}
          onChange={e => {
            setFieldValue(e.target.name, e.target.value.trim());
          }}
          type="text"
          value={values.item[index].enlace}
          variant="outlined"
        />

        {/* ▼ IMAGEN para el contenedor del item. */}
        <EditorImage
          label="Imagen principal"
          editorRef={values.item[index].editorRefImage}
          borderRadiusProp={values.item[index].imageStyles.borderRadius}
          widthProp={values.item[index].imageStyles.width}
          heightProp={values.item[index].imageStyles.height}
          positionXProp={values.item[index].imageStyles.positionX}
          positionYProp={values.item[index].imageStyles.positionY}
          rotateProp={values.item[index].imageStyles.rotate}
          scaleProp={values.item[index].imageStyles.scale}
          handleBlur={handleBlur}
          handleChange={fileImage => {
            values.item[index].partialFileName = '';
            setFieldValue(`item[${index}].image`, fileImage);
          }}
          setFileTouched={value =>
            (values.item[index].editorSetMainImageFileTouched = value)
          }
          touched={values.item[index].editorSetMainImageFileTouched}
          errors={errors.image}
          value={values.item[index].image}
        />

        {/** Texto alternativo para la imagen */}
        <TextField
          fullWidth
          label="Texto alternativo para la imagen"
          margin="normal"
          name={`item.${index}.alt_text`}
          onBlur={handleBlur}
          onChange={e => {
            setFieldValue(e.target.name, e.target.value);
          }}
          type="text"
          value={values.item[index].alt_text}
          variant="outlined"
        />

        {/* COLOR DE FONDO EN CAJA DEL TÍTULO DEL AGRUPADOR */}
        <Grid container>
          <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2 pb-3">
            <ChromePicker
              width="100%"
              color={values.item[index].bg_color}
              onChangeComplete={color => {
                values.item[index].bg_color = color.hex;
                setFieldValue('title_bg_color', color.hex);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubformItemsSliderItem;
