import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import DeleteItem from 'src/components/shared/DeleteItem';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import NewsPagesService from 'src/services/NewsPagesService';
import { showDeveloperMessageConsole, stripHTML } from 'src/helpers/random';

const ITEM_HEIGHT = 48;

export async function preview(page) {

  let json_data = page.item?.map(item => {
    if (!item.filename && !item.image) return
    let { editorRefImage, editorSetMainImageFileTouched, ...resto } = item;
    return { ...resto };
  });

  const noticiaJSON = {
    title: page.title ? page.title : "Título",
    copete: page.copete ? page.copete : "Copete",
    slug: page.slug ? page.slug : "slug",
    body: page.body ? page.body : "Cuerpo de la noticia.",
    publication_date: page.publication_date ? page.publication_date : null,
    post_date: page.post_date ? page.post_date : null,
    background_color: page.background_color ? page.background_color : "#FFF",
    json_data: page.json_data ? page.json_data : (json_data ? JSON.stringify(json_data) : "")
  }

  const { response } = await NewsPagesService.temporalPreview(noticiaJSON)
    .then((res) => res.data)
    .catch((error) => {
      showDeveloperMessageConsole('Error al generar el archivo JSON de las paginas institucionales: ', error);
    });

  window.open(`${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${response.id}`, '_blank', 'noopener, noreferrer');
}

function Actions({
  pagina, deletePagina, hasPermission
}) {
  const match = useRouteMatch();
  const { id: pageId, title: pageTilte } = pagina;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  // const previewURL = `${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${pageId}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          hasPermission.edit
          && (
            <MenuItem
              onClick={() => {
                history.push(`${match.path}/${routesApp.newsPage.children.edit.path.replace(':paginaId', pageId)}`);
              }}
            >
              Editar
            </MenuItem>
          )
        }
        <MenuItem onClick={() => preview(pagina)}>
          Previsualizar
        </MenuItem>
        {
          hasPermission.delete
          && (
            <DeleteItem
              deleteAction={deletePagina}
              item={pageId}
              message={`¿Deseas eliminar la página "${stripHTML(pageTilte)}"?`}
              title="Eliminar Página"
            />
          )
        }
      </Menu>
    </div>
  );
}

export default Actions;
