/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable quotes */
/* eslint-disable indent */
import React, { useState } from "react";
import * as Yup from 'yup';
import CKEditorBoton from "src/components/shared/CKEditor/CKEditorBoton";
import { Form, Formik } from "formik";
import { FormControl, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { getOptionsSize } from "src/helpers/components";
import { routesApp } from "src/routesService";
import { SketchPicker } from "react-color";
import { URLRegex } from "src/helpers/random";
import { useRouteMatch } from "react-router-dom";
import { BackButton, SaveButton } from "src/components/shared/Buttons";

import SelectField from 'src/components/shared/SelectField';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';



export default function FormBoton(props)
{
    const {
        componentType,
        component,
        loading,
        updateComponent,
        addComponent,
        filas,
        addFila,
    } = props;
    const [ maxSize, setMaxSize ] = useState(component ? getOptionsSize(component.col) : []);
    const match = useRouteMatch();

    return (
<Formik
    initialValues={{
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
        alineacion: component ? component.json_data?.align_in_column : {label:"centrado", value:"center"},
        title: component ? (component.title || '') : '',
        link: component ? (component.url || '') : '',
        bg_color: component ? component.json_data?.bg_color : "#0072BB",
        hover_color: component ? component.json_data?.hover_color : "#0481D2",
    }}
    validationSchema={Yup.object().shape({
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
        title: Yup.string().required('El título es requerido'),
        link: Yup.string().required('El enlace es requerido').test(
            'valid-url',
            'La URL ingresada es inválida',
            (str) => ((str === null || str === undefined) ? true : !!URLRegex.test(str))
        ),
        bg_color: Yup.string().required('El color del fondo es requerido'),
        hover_color: Yup.string().required('El color del hover es requerido'),
    })}
    onSubmit={async (data) =>
    {
        const formData = new FormData();

        formData.append('front_page_id', match.params.portadaId);
        formData.append('component_type_id', componentType.value);

        formData.append('row', data.fila.value)
        formData.append('col', data.columna.value)
        formData.append('size', data.tamanio.value)
        formData.append('title', data.title);
        formData.append('url', data.link);
        formData.append('json_data', JSON.stringify({
            bg_color: data.bg_color,
            hover_color: data.hover_color,
            align_in_column: data.alineacion,
        }));

        if (component) {
            await updateComponent(formData, component.id);
        } else {
            await addComponent(formData);
        }
    }}
>
    {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        isSubmitting,
    }) => (
    <Form className="w-100">
        <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">

                { /* UBICACIÓN */ }
                <Typography color="textPrimary" variant="overline">UBICACIÓN</Typography>

                {/* DROPDOWN FILA */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                    <SelectField
                    id="fila-titulo"
                    name="fila"
                    placeholder="Seleccione una fila..."
                    getOptions={() => filas}
                    label="Fila"
                    setFieldValue={(value) => {
                        setFieldValue('fila', value);
                        setFieldValue('columna', '');
                        setFieldValue('tamanio', '');
                    }}
                    value={values.fila}
                    addNewOption
                    addOption={() => addFila()}
                    />
                    <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
                </FormControl>

                {/* DROPDOWN COLUMNA */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                    <SelectField
                    id="columna-titulo"
                    name="columna"
                    placeholder="Seleccione una columna..."
                    getOptions={() => TAMANIOS_COMPONENTES_OPTIONS}
                    label="Columna"
                    setFieldValue={(value) => {
                        setFieldValue('columna', value);
                        setFieldValue('tamanio', '');
                        setMaxSize(getOptionsSize(value.value))
                    }}
                    value={values.columna}
                    />
                    <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
                </FormControl>

                {/* DROPDOWN P/ANCHO EN COLUMNAS DEL COMPONENTE */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                    <SelectField
                    id="tamanio-titulo"
                    name="tamanio"
                    placeholder="Seleccione un tamaño..."
                    getOptions={() => maxSize}
                    label="Tamaño"
                    setFieldValue={(value) => {
                        setFieldValue('tamanio', value);
                    }}
                    value={values.tamanio}
                    />
                    <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
                </FormControl>

                {/* DROPDOWN ALINEACIÓN DEL BOTÓN DENTRO DE LA COLUMNA */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                    <SelectField
                    id="titulo-alineacion"
                    name="alineacion"
                    placeholder="Seleccione alineación del botón..."
                    getOptions={() => [
                        {label:"izquierda", value:"flex-start"},
                        {label:"centrado", value:"center"},
                        {label:"derecha", value:"flex-end"},
                    ]}
                    label="Alineación"
                    setFieldValue={value => setFieldValue('alineacion', value)}
                    value={values.alineacion}
                    />
                    <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
                </FormControl>

                { /* TÍTULO */ }
                <FormControl margin="normal" fullWidth error={Boolean((touched.title && errors.title))}>
                    <Typography color="textPrimary" variant="overline">Título</Typography>
                    <CKEditorBoton
                        id="ck-title-desplazada"
                        value={values.title}
                        name="title"
                        styles={[
                        { name: 'height', value: '150px' },
                        ]}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    />
                    <FormHelperText className="text-danger">{(touched.title && errors.title)}</FormHelperText>
                </FormControl>

                {/* URL DEL BOTÓN */}
                <FormControl margin="normal" fullWidth error={Boolean((touched.link && errors.link))}>
                    <Typography color="textPrimary" variant="overline">ENLACE</Typography>
                    <TextField
                        error={Boolean(touched.link && errors.link)}
                        fullWidth
                        helperText={touched.link && errors.link}
                        label="Enlace"
                        margin="normal"
                        name="link"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.link}
                        variant="outlined"
                    />
                </FormControl>

                { /* BACKGROUND */ }
                <Typography color="textPrimary" variant="overline">BACKGROUND</Typography>
                <div style={{
                    display: "flex",
                    gap: "2rem",
                }}>
                    <FormControl margin="normal" fullWidth error={Boolean((touched.bg_color && errors.bg_color))}>
                        <Typography color="textSecondary" variant="overline">Fondo del botón</Typography>
                        <SketchPicker
                            width="100%"
                            color={values.bg_color}
                            onChangeComplete={(color) => setFieldValue('bg_color', color.hex)}
                        />
                        <FormHelperText className="text-danger">{(touched.bg_color && errors.bg_color) && errors.bg_color}</FormHelperText>
                    </FormControl>

                    <FormControl margin="normal" fullWidth error={Boolean((touched.hover_color && errors.hover_color))}>
                        <Typography color="textSecondary" variant="overline">Mouse over</Typography>
                        <SketchPicker
                            width="100%"
                            color={values.hover_color}
                            onChangeComplete={(color) => setFieldValue('hover_color', color.hex)}
                        />
                        <FormHelperText className="text-danger">{(touched.hover_color && errors.hover_color) && errors.hover_color}</FormHelperText>
                    </FormControl>

                </div>

            </Grid>

            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                <BackButton disabled={isSubmitting} to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)} />
                <SaveButton disabled={isSubmitting} >Guardar</SaveButton>
            </Grid>

        </Grid>
    </Form>
    )}
</Formik>
    )
}
