import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles

} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {Home as HomeIcon} from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; 
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/shared/Logo';
import { routesApp } from 'src/routesService';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight:64,
    paddingLeft:0,
    zIndex: 1210,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  avatar: {
    width: 60,
    height: 60
  },
  appBarShift: {
    marginLeft: 256,
    width: `calc(100% - ${256}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const DashboardLayoutTopBar = ({
  className,
  navOpen,
  onNavOpen,
  toggleNavOpen,
  logout,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className, navOpen && classes.appBarShift)}
      elevation={0}
      {...rest}
    >
      <Toolbar style={{paddingLeft:14}}>
        <Hidden mdDown>
          <div style={{ display: navOpen ? 'none' : 'block' }}>
            <IconButton
              color="inherit"
              onClick={toggleNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Hidden>
        <Link to={routesApp.inicio.path} title="Inicio">
          <HomeIcon style={{color:"#fff", marginLeft:15}}/>
        </Link>
        <Box flexGrow={1} />

        <Hidden mdDown>
        <a href={process.env.REACT_APP_JUANAMANSO_REA} aria-label='Ir a la pagina de inicio'><Logo style={{marginRight:30}}/></a>
          <Link to={routesApp.profile.path} title="Perfil">
            <AccountCircleIcon style={{color:"#fff"}} />
          </Link>
          <IconButton color="inherit" title="Salir" onClick={()=>{
            logout()
          }}>
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardLayoutTopBar.propTypes = {
  className: PropTypes.string,
  onNavOpen: PropTypes.func
};

export default DashboardLayoutTopBar;
