import api from '../api';
import preview from '../api/preview'

const PortadasService = {
  getPortadas: (page, limit, filters) => {
    return api.get(`front_page?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  getOnePortada: (id) => {
    return api.get(`front_page/${id}`);
  },
  addPortada: (data) => {
    return api.post(`front_page`, data);
  },
  updatePortada: (data, id) => {
    return api.put(`front_page/${id}`, data);
  },
  deletePortada: (id) => {
    return api.delete(`front_page/${id}`);
  },
  publishPortada:(id)=>{
    return api.post(`front_page/${id}/post`);
  },
  despublicarPortada:(id)=>{
    return api.post(`front_page/${id}/despublicar`);
  },
  getComponentes: (idPortada, filters) => {
    return api.get(`front_page/${idPortada}/page_component${filters}`);
  },
  getOneComponent: (id) => {
    return api.get(`page_component/${id}`);
  },
  addComponent: (data) => {
    return api.post(`page_component`, data)
  },
  updateComponent: (data, id) => {
    return api.post(`page_component/${id}?_method=PUT`, data)
  },
  deleteComponente: (id) => {
    return api.delete(`page_component/${id}`);
  },
  getTypesComponent: () => {
    return api.get(`page_component/type`);
  },
  generatePreviewPortada: (id) => {
    return preview.post(`front_page/json/${id}`, {});
  },
  getListActive:()=>{
    return api.get(`front_page/list`)
  }
};

export { PortadasService as default };


