import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Box, Button, makeStyles
} from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';

import { routesApp } from 'src/routesService'
import { checkPermissions } from 'src/helpers/permissions'
import { COMPONENTES_PERMISSIONS } from 'src/utils/constants/permissions'
import PageContainer from 'src/containers/shared/PageContainer'
import PortadasService from 'src/services/PortadasService';
import Loading from 'src/components/shared/loading';

import TableComponents from './shared/tableComponentes'
import { PrimaryLinkButton } from 'src/components/shared/Buttons/PrimaryLinkButton';
import { FrontPageStatus } from './shared/chips/FrontPageStatus';
import { Loader } from 'react-feather';

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  }
}));

const ComponentsList = ({
  componentes, pagination, loading, portadaId, ...rest
}) => {
  const classes = useStyles()
  const { permissions } = useSelector((state) => state.auth.data)
  const [portada, setPortada] = useState(null);

  const hasPermission = {
    view: checkPermissions(permissions, [COMPONENTES_PERMISSIONS.VIEW_COMPONENTE]),
    add: checkPermissions(permissions, [COMPONENTES_PERMISSIONS.CREATE_COMPONENTE]),
    edit: checkPermissions(permissions, [COMPONENTES_PERMISSIONS.EDIT_COMPONENTE]),
    delete: checkPermissions(permissions, [COMPONENTES_PERMISSIONS.DELETE_COMPONENTE]),
  }

  function handlePreview() {
    PortadasService.generatePreviewPortada(portada.id).then(() => {
      const url = `${process.env.REACT_APP_JUANAMANSO_REA}preview/portada/${portada.id}`
      window.open(url, '_blank', 'noopener, noreferrer')
    });
  }

  useEffect(()=>{
    PortadasService.getOnePortada(portadaId)
    .then(res => setPortada(res.data))
  }, [])

  if(portada == null) {return <Loading />}
   return (
    <PageContainer title="Componentes" maxWidth={false}>
      {
        hasPermission.add
        && (
        <Box
          className="flex-container"
          display="flex"
          justifyContent="space-between"
        >
          <div>
            <Link to={routesApp.portadas.path}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<BackIcon />}
                className={classes.color}
              >
                Atrás
              </Button>
            </Link>
            <PrimaryLinkButton to={routesApp.addComponente.path.replace(':portadaId', portada.id)}>
              + Añadir Componente
            </PrimaryLinkButton>
          </div>
          <FrontPageStatus state={portada.state} />
          <Button
            color="primary"
            variant="contained"
            onClick={handlePreview}
          >
            Previsualizar
          </Button>
        </Box>
        )
      }
      <Box mt={3}>
        <TableComponents
          data={componentes}
          currentPage={pagination.current_page}
          itemsCountPerPage={pagination.per_page}
          totalItemsCount={pagination.total}
          nextPage={pagination.next_page_url}
          prevPage={pagination.prev_page_url}
          lastPage={pagination.last_page}
          loading={loading}
          abilities={hasPermission}
          getAllComponentes={rest.getAllComponentes}
          deleteComponente={rest.deleteComponente}
          publicarPortada={rest.publicarPortada}
          portadaId={portada.id}
          portadaTitle={portada.name}
        />
      </Box>

    </PageContainer>
  )
}

export default ComponentsList
