import React from 'react';
import { Form, Formik } from 'formik';
import { SketchPicker } from 'react-color';
import * as Yup from 'yup';
import {
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useHistory } from 'react-router-dom';

import { routesApp } from 'src/routesService';
// eslint-disable-next-line camelcase
import {
  string_to_slug,
  getDataFormatedForSelects,
  slug_to_string,
  showDeveloperMessageConsole
} from 'src/helpers/random';
import NewsPagesService from 'src/services/NewsPagesService';
import CKEditorFull from 'src/components/shared/CKEditor/CKEditorFull';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import CKEditorCopete from 'src/components/shared/CKEditor/CKEditorCopete';
import EditorImage from 'src/components/shared/EditorImage';
import SelectField from '../../shared/SelectField';
import DatePickerField from '../../shared/Forms/DatePickerField';

import { preview } from './Actions';

const theme = createTheme({
  palette: {
    secondary: red
  }
});

const useStyles = makeStyles(_theme => ({
  color: {
    margin: _theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));


export const itemInitialValuesImageStyles = {
  borderRadiusProp: 0,
  widthProp: 250,
  heightProp: 250,
  positionXProp: 0.5,
  positionYProp: 0.5,
  rotateProp: 0,
  scaleProp: 1
};

export const itemInitialValues = {
  image: '',
  partialFileName: '',
  alt_text: '',
  filename: '',
  imageStyles: itemInitialValuesImageStyles,
  editorRefImage: React.createRef(),
  editorSetMainImageFileTouched: false
};

const transformarItems = itemsJson => {
  const items = JSON.parse(itemsJson);

  for (const item of items) {
    item.image = item.partialFileName
      ? `${process.env.REACT_APP_BASE_URL_API}/${item.partialFileName}`
      : '';
    item.partialFileName = item.partialFileName || '';
    item.alt_text = item.alt_text || '';
    item.filename = item.filename || '';
    item.imageStyles = item.imageStyles || '';
    item.editorRefImage = React.createRef();
    item.editorSetMainImageFileTouched = false;
  }

  if (items.length < 2)
    items.push(itemInitialValues);
  
  return items;
};


/* async function preview(page) {
  let json_data = page.item.map(item => {
    if(!item.filename) return
    let { editorRefImage, editorSetMainImageFileTouched, ...resto } = item;
    return { ...resto };
  });

  const noticiaJSON = {
    title: page.title ? page.title : "Título",
    copete: page.copete ? page.copete : "Copete",
    slug: page.slug ? page.slug : "slug",
    body: page.body ? page.body : "Cuerpo de la noticia.",
    publication_date: page.publication_date ? page.publication_date : null,
    post_date: page.post_date ? page.post_date : null,
    background_color: page.background_color ? page.background_color : "#FFF",
    json_data: json_data ? JSON.stringify(json_data) : ""
  }
    
  const { response } = await NewsPagesService.temporalPreview( noticiaJSON )
    .then(res => res.data)
    .catch(error => {
      showDeveloperMessageConsole(
        'Error al generar el archivo JSON de las noticias: ',
        error
      );
    });

  window.open(
    `${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${response.id}`,
    '_blank',
    'noopener, noreferrer'
  );
} */

const appendListaImagenes = (dataNoticias, formData) => {
  const listaImagenes = dataNoticias.item.filter((item) => {
    return item.image !== '';
  });

  for (const item of listaImagenes) {
    item.filename = item.image.name;
    formData.append('imagenes[]', item.image);
    item.imageStyles = {
      borderRadius: item.editorRefImage.current.props.borderRadius,
      height: item.editorRefImage.current.props.height,
      width: item.editorRefImage.current.props.width,
      rotate: item.editorRefImage.current.props.rotate,
      scale: item.editorRefImage.current.props.scale,
      positionX: item.editorRefImage.current.props.position.x,
      positionY: item.editorRefImage.current.props.position.y
    };
    delete item.editorRefImage;
    delete item.editorSetMainImageFileTouched;
  }
  return listaImagenes;
};

const FormNewsPage = props => {
  const { action, noticia, states, loading, user } = props;
  const classes = useStyles();
  const history = useHistory();

  const getEstadosOptions = () => {
    return getDataFormatedForSelects(states, 'id', 'display_name');
  };

  const slug = noticia.slug?.replace(/\//g, '').replace('noticias', '') ?? '';

  return (
    <Formik
      initialValues={{
        title: noticia.title ?? '',
        state: noticia.state
          ? { value: noticia.state?.id, label: noticia.state?.display_name }
          : '',
        copete: noticia.copete ?? '',
        enlace: `noticias/${slug}`,
        slug,
        body: noticia.body ?? '',
        publication_date: noticia.publication_date ?? null,

        item: noticia && noticia.json_data
          ? transformarItems(noticia.json_data)
          : [itemInitialValues, itemInitialValues],

        background_color: noticia && noticia.background_color ? noticia.background_color : '#FFFFFF',

        url: (() => {
          if (noticia.slug) {
            const splittedSlug = noticia.slug.split('/');
            if (splittedSlug.length > 1) {
              return slug_to_string(splittedSlug[1]);
            }
          }
          return '';
        })()
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .max(255, 'El título no debe superar los 255 caracteres')
          .required('El título es requerido'),
        state: Yup.string().required('El estado es requerido'),
        copete: Yup.string()
          .max(255, 'El copete no debe superar los 255 caracteres')
          .required('El copete es requerido'),
        slug: Yup.string()
          .max(255, 'El slug no debe superar los 255 caracteres')
          .required('El slug es requerido'),
        body: Yup.string().required('El cuerpo de la página es requerido'),
        publication_date: Yup.string()
          .required('La fecha de publicación es requerida')
          .nullable(),
        background_color: Yup.string().required('El color de fondo es requerido'),
        url: Yup.string().required('El nombre de la URL es requerido'),

        item: Yup.array().of(
          Yup.object().shape({
            image: Yup.string().when('$isFirstItem', {
              is: true,
              then: Yup.string().required('La imagen es requerida'),
              otherwise: Yup.string()
            }),
            alt_text: Yup.string().when('image', {
              is: value => Boolean(value),
              then: Yup.string().required('El texto alternativo es requerido'),
              otherwise: Yup.string().nullable()
            })
          })
        )
        .test('first-item-required', 'El primer elemento es requerido', function (items) {
          if (items && items.length > 0) {
            const firstItem = items[0];
            if (!firstItem.image || !firstItem.alt_text) {
              return this.createError({ path: 'item[0].image', message: 'La imagen es requerida' });
            }
          }
          return true;
        })
        .test('second-item-alt-text-required', 'El texto alternativo es requerido para la segunda imagen', function (items) {
          if (items && items.length > 1) {
            const secondItem = items[1];
            if (secondItem.image && !secondItem.alt_text) {
              return this.createError({ path: 'item[1].alt_text', message: 'El texto alternativo es requerido' });
            }
          }
          return true;
        })
      })}
      onSubmit={async data => {
        const formdata = new FormData();
        formdata.append('id', noticia ? noticia.id : null);
        formdata.append('title', data.title);
        formdata.append('state_id', data.state.value);
        formdata.append('copete', data.copete);
        formdata.append('slug', data.slug);
        formdata.append('body', data.body);
        formdata.append('publication_date', data.publication_date);
        formdata.append('background_color', data.background_color);
        
        const imagenes = appendListaImagenes(data, formdata);

        const stringifiedJSONobject = JSON.stringify(imagenes);

        formdata.append('json_data', stringifiedJSONobject);

        await action(noticia.id)(formdata);

        history.push('/app/contenidos/paginas-noticias');
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        isSubmitting,
        values
      }) => (
        <Form>
          <Grid container>
            {/* General */}
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography
                color="textPrimary"
                variant="overline"
                className="font-weight-bold"
              >
                General
              </Typography>
              <FormControl
                margin="normal"
                fullWidth
                error={Boolean(touched.title && errors.title)}
              >
                <Typography color="textPrimary" variant="overline">
                  Título
                </Typography>
                <CKEditorTitulo
                  id="ck-title-noticias"
                  value={values.title}
                  name="title"
                  styles={[{ name: 'height', value: '150px' }]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">
                  {touched.title && errors.title && errors.title}
                </FormHelperText>
              </FormControl>
              <FormControl
                margin="normal"
                fullWidth
                error={Boolean(touched.copete && errors.copete)}
              >
                <Typography color="textPrimary" variant="overline">
                  Copete
                </Typography>
                <CKEditorCopete
                  id="ck-copete-noticias"
                  value={values.copete}
                  name="copete"
                  styles={[{ name: 'height', value: '150px' }]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">
                  {touched.copete && errors.copete && errors.copete}
                </FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.url && errors.url)}
                fullWidth
                helperText={touched.url && errors.url}
                label="Nombre URL"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('url', e.target.value);
                  setFieldValue('slug', string_to_slug(e.target.value));
                  setFieldValue(
                    'enlace',
                    `noticias/${string_to_slug(e.target.value)}`
                  );
                }}
                type="text"
                value={values.url}
                variant="outlined"
              />
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                error={Boolean(touched.state && errors.state)}
              >
                <SelectField
                  placeholder="Seleccione estado..."
                  name="state"
                  getOptions={getEstadosOptions}
                  label="Estado"
                  setFieldValue={value => {
                    setFieldValue('state', value);
                  }}
                  isLoading={states.length < 1}
                  value={values.state}
                />
                <FormHelperText className="text-danger">
                  {touched.state && errors.state && errors.state}
                </FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.slug && errors.slug)}
                fullWidth
                helperText={touched.slug && errors.slug}
                label="Slug"
                margin="normal"
                name="slug"
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('slug', string_to_slug(e.target.value));
                  setFieldValue(
                    'enlace',
                    `noticias/${string_to_slug(e.target.value)}`
                  );
                }}
                type="text"
                value={values.slug}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.enlace && errors.enlace)}
                helperText={touched.enlace && errors.enlace}
                label="Enlace"
                name="enlace"
                placeholder="https://www.enlace.com"
                value={`${process.env.REACT_APP_JUANAMANSO_REA
                  }${values.enlace ?? ''}`}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled
              />
              <Grid item xs={12} md={12} style={{ margin: '1rem 0rem' }}>
                <FormControl
                  margin="normal"
                  fullWidth
                  error={Boolean(
                    touched.publication_date && errors.publication_date
                  )}
                >
                  <DatePickerField
                    id="date-picker-publication-date"
                    label="Fecha de publicación"
                    value={values.publication_date}
                    error={Boolean(
                      touched.publication_date && errors.publication_date
                    )}
                    onChange={date => setFieldValue('publication_date', date)}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  <FormHelperText className="text-danger">
                    {touched.publication_date &&
                      errors.publication_date &&
                      errors.publication_date}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>


            {/* Imágenes */}
            <Grid item xs={12} md={12}>
              <Typography
                color="textPrimary"
                variant="overline"
                className="font-weight-bold"
              >
                Imágenes
              </Typography>

              {/* Imagen miniatura */}
              <Grid container>
                <div className='img-input'>
                  <div className={(touched.item && errors.item && touched.item[0]?.image) ? "img-select val-error" : ""}>
                    <EditorImage
                      label="Imagen miniatura"
                      editorRef={values.item[0].editorRefImage}
                      borderRadiusProp={values.item[0].imageStyles.borderRadius}
                      widthProp={values.item[0].imageStyles.width}
                      heightProp={values.item[0].imageStyles.height}
                      positionXProp={values.item[0].imageStyles.positionX}
                      positionYProp={values.item[0].imageStyles.positionY}
                      rotateProp={values.item[0].imageStyles.rotate}
                      scaleProp={values.item[0].imageStyles.scale}
                      handleBlur={handleBlur}
                      handleChange={fileImage => {
                        values.item[0].partialFileName = '';
                        setFieldValue(`item[0].image`, fileImage);
                      }}
                      setFileTouched={value =>
                        (values.item[0].editorSetMainImageFileTouched = value)
                      }
                      touched={values.item[0].editorSetMainImageFileTouched}
                      errors={errors.item ? errors.item[0]?.image : null}
                      value={values.item[0].image}
                    />
                  </div>
                  <FormHelperText className="val-error-Txt">
                    {( touched.item && touched.item[0]?.image && errors.item && errors.item[0] 
                      ? errors.item[0]?.image
                      : '')}
                  </FormHelperText>
                </div>
              </Grid>
              <TextField
                error={Boolean(touched.item && errors.item && touched.item[0] &&  errors.item[0]?.alt_text)}
                fullWidth
                helperText={errors.item && errors.item ? errors.item[0]?.alt_text : null}
                label="Texto alternativo para la imagen miniatura"
                margin="normal"
                name={`item.0.alt_text`}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue("item[0].alt_text", e.target.value);
                }}
                type="text"
                value={values.item[0].alt_text}
                variant="outlined"
              />


              {/* Imagen de poster */}
              <Grid container>
                <div className='img-input'>
                  <div>
                    <EditorImage
                      label="Imagen poster"
                      editorRef={values.item[1].editorRefImage}
                      borderRadiusProp={values.item[1].imageStyles.borderRadius}
                      widthProp={values.item[1].imageStyles.width}
                      heightProp={values.item[1].imageStyles.height}
                      positionXProp={values.item[1].imageStyles.positionX}
                      positionYProp={values.item[1].imageStyles.positionY}
                      rotateProp={values.item[1].imageStyles.rotate}
                      scaleProp={values.item[1].imageStyles.scale}
                      handleBlur={handleBlur}
                      handleChange={fileImage => {
                        values.item[1].partialFileName = '';
                        setFieldValue(`item[1].image`, fileImage);
                      }}
                      setFileTouched={value =>
                        (values.item[1].editorSetMainImageFileTouched = value)
                      }
                      touched={values.item[1].editorSetMainImageFileTouched}
                      errors={''}
                      value={values.item[1].image}
                    />
                    <FormHelperText className="val-error-Txt">
                      {/* {(touched.image && errors.image) && errors.image} */}
                    </FormHelperText>
                  </div>
                </div>
              </Grid>
              <Grid container>
                <TextField
                  error={Boolean(touched.item && errors.item && touched.item[1] && errors.item[1]?.alt_text)}
                  helperText={errors.item && errors.item ? errors.item[1]?.alt_text : null}
                  fullWidth
                  label="Texto alternativo para la imagen poster"
                  margin="normal"
                  name={`item.1.alt_text`}
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue("item[1].alt_text", e.target.value);
                  }}
                  type="text"
                  value={values.item[1].alt_text}
                  variant="outlined"
                />
              </Grid>
              <Grid container>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setFieldValue(`item[1].imageStyles`, itemInitialValuesImageStyles);
                    setFieldValue(`item[1].image`, '');
                    setFieldValue(`item[1].alt_text`, '');
                  }}
                >
                  Quitar imagen poster
                </Button>
              </Grid>
            </Grid>

            {/* Color de fondo */}
            <Grid container>
              <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                  <Typography color="textPrimary" variant="overline" className="font-weight-bold">Background</Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                  <SketchPicker
                    width="100%"
                    color={values.background_color ? values.background_color : '#ffffff'}
                    onChangeComplete={(color) => setFieldValue('background_color', color.hex)}
                  />
                  <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            {/* Body */}
            <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
              <Typography
                color="textPrimary"
                variant="overline"
                className="font-weight-bold"
              >
                Cuerpo de la página
              </Typography>
              <FormControl
                margin="normal"
                fullWidth
                error={Boolean(touched.body && errors.body)}
              >
                <CKEditorFull
                  permissions={user.permissions}
                  id="noticias"
                  value={values.body}
                  name="body"
                  styles={[{ name: 'height', value: '400px' }]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">
                  {touched.body && errors.body && errors.body}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.newsPage.path}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<BackIcon />}
                    className={classes.color}
                  >
                    Atrás
                  </Button>
                </Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading || isSubmitting}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
              <Button
                className="ml-3"
                color="secondary"
                onClick={async () => preview(values)}
              >
                Previsualizar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FormNewsPage;
