/* eslint-disable max-len */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// import moment from 'moment';
import {
  Button,
  createTheme,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
  RadioGroup, Radio,
  FormControlLabel,
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import CKEditorCopete from 'src/components/shared/CKEditor/CKEditorCopete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { routesApp } from 'src/routesService';
import { selectFileWithCKFinder, URLRegex } from 'src/helpers/random';

import SelectField from 'src/components/shared/SelectField';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import EditorImage from 'src/components/shared/EditorImage';
import { addNewFila, getOptionsRows } from 'src/helpers/components';
import DatePickerField from 'src/components/shared/Forms/DatePickerField';

import { SketchPicker } from "react-color";

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormNoticia = (props) => {
  const classes = useStyles()
  const match = useRouteMatch()

  const {
    componentType,
    component,
    loading,
    allComponents,
    appendImage,
    updateComponent,
    addComponent,
    appendBackground,
  } = props;

  const editorRefImage = React.createRef()

  const [background, setBackground] = useState(component ? (component.page_background.color_hex ? '2' : '1') : '2');
  const [filas, setFilas] = useState(getOptionsRows(allComponents));
  const [mainImageFileTouched, setMainImageFileTouched] = React.useState(false);

  return (
    <Formik
      initialValues={{
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
        title: component ? component.title : '',
        copete: component ? component.copete : '',
        image: component ? `${process.env.REACT_APP_BASE_URL_API}/${component.image_file.filename}` : '',
        alt_text: component ? component.image_file.alt_text : '',
        link: component ? component.url : '',
        publication_date: component?.publication_date ?? null,
        color_background: component && component.page_background.color_hex ? component.page_background.color_hex : '',
        hover_color: component && component.json_data && component.json_data.hover_color ? component.json_data.hover_color : '',
        hover_text: component && component.json_data && component.json_data.hover_text ? component.json_data.hover_text : '',
      }}
      validationSchema={Yup.object().shape({
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        title: Yup.string().required('El título es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
        copete: Yup.string().required('El copete es requerido'),
        image: Yup.string().required('La imagen es requerida'),
        link: Yup.string().nullable()
          .test(
            'valid-url',
            'La URL ingresada es inválida',
            (str) => ((str === null || str === undefined) ? true : !!URLRegex.test(str))
          ),
        alt_text: Yup.string().required('El texto alternativo es requerido'),
        publication_date: Yup.string().required('La fecha de publicación es requerida').nullable(),
        color_background: Yup.string().required('El color del background es requerido'),
      })}
      onSubmit={async (data) => {
        const formData = new FormData()

        formData.append('front_page_id', match.params.portadaId)
        formData.append('component_type_id', componentType.value)

        let date;
        try {
          date = data.publication_date.format('YYYY-MM-DD HH:mm:ss');
        } catch (error) {
          date = data.publication_date;
        }

        formData.append('row', data.fila.value)
        formData.append('col', data.columna.value)
        formData.append('size', data.tamanio.value)
        formData.append('title', data.title)
        formData.append('copete', data.copete)
        formData.append('url', data.link);
        formData.append('publication_date', date);

        formData.append('json_data', JSON.stringify({
          hover_color: data.hover_color,
          hover_text: data.hover_text,
        }));

        appendImage(formData, data, editorRefImage.current, mainImageFileTouched);
        appendBackground(formData, background, data);

        if (component) {
          await updateComponent(formData, component.id)
        } else {
          await addComponent(formData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }} id='FormNoticia'>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                <SelectField
                  id="fila-titulo"
                  name="fila"
                  placeholder="Seleccione una fila..."
                  getOptions={() => filas}
                  label="Fila"
                  setFieldValue={(value) => {
                    setFieldValue('fila', value);
                    setFieldValue('columna', '');
                    setFieldValue('tamanio', '');
                  }}
                  value={values.fila}
                  addNewOption
                  addOption={() => setFilas(addNewFila(filas))}
                />
                <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                <SelectField
                  id="columna-titulo"
                  name="columna"
                  placeholder="Seleccione una columna..."
                  getOptions={() => TAMANIOS_COMPONENTES_OPTIONS.slice(0, 4)}
                  label="Columna"
                  setFieldValue={(value) => {
                    setFieldValue('columna', value);
                    setFieldValue('tamanio', '');
                  }}
                  value={values.columna}
                />
                <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
              </FormControl>

              <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                <SelectField
                  id="tamanio-titulo"
                  name="tamanio"
                  placeholder="Seleccione el tamaño..."
                  getOptions={() => TAMANIOS_COMPONENTES_OPTIONS.slice(0, 4)}
                  label="Tamaño"
                  setFieldValue={(value) => {
                    setFieldValue('tamanio', value);
                  }}
                  value={values.tamanio}
                />
                <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
              </FormControl>

              <FormControl margin="normal" fullWidth error={Boolean((touched.title && errors.title))}>
                <Typography color="textPrimary" variant="overline">Título</Typography>
                <CKEditorTitulo
                  id="ck-title-desplazada"
                  value={values.title}
                  name="title"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.title && errors.title)}</FormHelperText>
              </FormControl>
              <FormControl className="ckeditor-height" margin="normal" fullWidth error={Boolean(touched.copete && errors.copete)}>
                <Typography color="textPrimary" variant="overline">Copete</Typography>
                <CKEditorCopete
                  id="ck-copete-desplazada"
                  value={values.copete}
                  name="copete"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.copete && errors.copete)}</FormHelperText>
              </FormControl>
              <Grid item xs={12} md={12} style={{ margin: '1rem 0rem' }}>
                <FormControl margin="normal" fullWidth error={Boolean((touched.publication_date && errors.publication_date))}>
                  <DatePickerField
                    id="date-picker-publication-date"
                    label="Fecha de publicación"
                    value={values.publication_date}
                    error={Boolean(touched.publication_date && errors.publication_date)}
                    onChange={(date) => setFieldValue('publication_date', date)}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  <FormHelperText className="text-danger">{(touched.publication_date && errors.publication_date) && errors.publication_date}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {
            process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
              ? (
                <div className='img-input'>
                  <div className={(touched.image && errors.image) ? "img-select val-error" : ""}>
                    <EditorImage
                      label="Imagen principal"
                      editorRef={editorRefImage}
                      borderRadiusProp={component && JSON.parse(component.image_file.styles).borderRadius ? JSON.parse(component.image_file.styles).borderRadius : 0}
                      widthProp={component && JSON.parse(component.image_file.styles).width ? JSON.parse(component.image_file.styles).width : 250}
                      heightProp={component && JSON.parse(component.image_file.styles).height ? JSON.parse(component.image_file.styles).height : 250}
                      positionXProp={component && JSON.parse(component.image_file.styles).positionX ? JSON.parse(component.image_file.styles).positionX : 0.5}
                      positionYProp={component && JSON.parse(component.image_file.styles).positionY ? JSON.parse(component.image_file.styles).positionY : 0.5}
                      rotateProp={component && JSON.parse(component.image_file.styles).rotate ? JSON.parse(component.image_file.styles).rotate : 0}
                      scaleProp={component && JSON.parse(component.image_file.styles).scale ? JSON.parse(component.image_file.styles).scale : 1}
                      handleBlur={handleBlur}
                      handleChange={(value) => setFieldValue('image', value)}
                      setFileTouched={setMainImageFileTouched}
                      touched={mainImageFileTouched}
                      errors={errors.image}
                      value={values.image}
                    />
                  </div>
                  <FormHelperText className="val-error-Txt">
                    {(touched.image && errors.image) && errors.image}
                  </FormHelperText>
                </div>
              )
              : (
                <Grid container>
                  <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                    <TextField
                      error={Boolean(errors.image && errors.image)}
                      fullWidth
                      helperText={touched.image && errors.image}
                      label="Imagen Destacado"
                      margin="normal"
                      name="image"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.image}
                      variant="outlined"
                    // disabled
                      InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image')}><CloudUploadIcon /></IconButton> }}
                    />
                  </Grid>
                </Grid>
              )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.alt_text && errors.alt_text)}
                fullWidth
                helperText={touched.alt_text && errors.alt_text}
                label="Texto alternativo de la imagen destacado"
                margin="normal"
                name="alt_text"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.alt_text}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.link && errors.link)}
                fullWidth
                helperText={touched.link && errors.link}
                label="Enlace"
                margin="normal"
                name="link"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.link}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Background</Typography>

                <FormLabel component="legend">Tipo de background</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={background} onChange={handleChange}>
                  {/* <FormControlLabel value="1" onChange={() => setBackground('1')} control={<Radio />} label="Imagen" /> */}
                  <FormControlLabel value="2" onChange={() => setBackground('2')} control={<Radio />} label="Color" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              {
                background === '2'
                && (
                <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                  <SketchPicker
                    width="100%"
                    color={values.color_background ? values.color_background : '#ffffff'}
                    onChangeComplete={(color) => setFieldValue('color_background', color.hex)}
                  />
                  <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                </FormControl>
                )
              }
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={3} className="pr-3 pl-3 pt-2">
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">On hover del background</Typography>
              </FormControl>
              
                  <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                    <SketchPicker
                      width="100%"
                      color={values.hover_color}
                      onChangeComplete={(color) => setFieldValue('hover_color', color.hex)}
                    />
                    <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                  </FormControl>
              
            </Grid>
            <Grid item xs={12} md={3} className="pr-3 pl-3 pt-2">
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">On hover del texto</Typography>
              </FormControl>
             
                  <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                    <SketchPicker
                      width="100%"
                      color={values.hover_text}
                      onChangeComplete={(color) => setFieldValue('hover_text', color.hex)}
                    />
                    <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                  </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormNoticia;
