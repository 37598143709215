export const PERMISSION_TYPES = {
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILURE: 'GET_PERMISSIONS_FAILURE',
};

export const ROLE_TYPES = {
  GET_ROLES: 'GET_ROLES',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',
  STORE_ROL:'STORE_ROL',
  STORE_ROL_SUCCESS:'STORE_ROL_SUCCESS',
  STORE_ROL_FAILURE:'STORE_ROL_FAILURE',
  GET_ONE_ROL: "GET_ONE_ROL",
  GET_ONE_ROL_SUCCESS: "GET_ONE_ROL_SUCCESS",
  GET_ONE_ROL_FAILURE: "GET_ONE_ROL_FAILURE",
};

export const FOOTER_TYPES = {
  GET_FOOTERS: 'GET_FOOTERS',
  GET_FOOTERS_SUCCESS: 'GET_FOOTERS_SUCCESS',
  GET_FOOTERS_FAILURE: 'GET_FOOTERS_FAILURE',
  GET_ONE_FOOTER: "GET_ONE_FOOTER",
  GET_ONE_FOOTER_SUCCESS: "GET_ONE_FOOTER_SUCCESS",
  GET_ONE_FOOTER_FAILURE: "GET_ONE_FOOTER_FAILURE",
  STORE_FOOTER: 'STORE_FOOTER',
  STORE_FOOTER_SUCCESS: 'STORE_FOOTER_SUCCESS',
  STORE_FOOTER_FAILURE: 'STORE_FOOTER_FAILURE',
  GET_TEXT_FOOTER: "GET_TEXT_FOOTER",
  GET_TEXT_FOOTER_SUCCESS: "GET_TEXT_FOOTER_SUCCESS",
  GET_TEXT_FOOTER_FAILURE: "GET_TEXT_FOOTER_FAILURE",
  STORE_TEXT_FOOTER: "STORE_TEXT_FOOTER",
  STORE_TEXT_FOOTER_SUCCESS: "STORE_TEXT_FOOTER_SUCCESS",
  STORE_TEXT_FOOTER_FAILURE: "STORE_TEXT_FOOTER_FAILURE",
  GET_TEXT_FOOTER2: "GET_TEXT_FOOTER2",
  GET_TEXT_FOOTER_SUCCESS2: "GET_TEXT_FOOTER_SUCCESS2",
  GET_TEXT_FOOTER_FAILURE2: "GET_TEXT_FOOTER_FAILURE2",
  STORE_TEXT_FOOTER2: "STORE_TEXT_FOOTER2",
  STORE_TEXT_FOOTER_SUCCESS2: "STORE_TEXT_FOOTER_SUCCESS2",
  STORE_TEXT_FOOTER_FAILURE2: "STORE_TEXT_FOOTER_FAILURE2",
  GET_PREV_FOOTERS: 'GET_PREV_FOOTERS',
  GET_PREV_FOOTERS_SUCCESS: 'GET_PREV_FOOTERS_SUCCESS',
  GET_PREV_FOOTERS_FAILURE: 'GET_PREV_FOOTERS_FAILURE',
};

export const MENU_USR_TYPES = {
  LOADING: 'LOADING_MENU_USRS',
  NOT_LOADING: 'LOADING_MENU_USRS',
  GET_MENUS_USR: 'GET_MENUS_USRS',
  GET_MENUS_USR_SUCCESS: 'GET_MENUS_USR_SUCCESS',
  GET_MENUS_USR_FAILURE: 'GET_MENUS_USR_FAILURE',
  GET_ONE_MENU_USR: "GET_ONE_MENU_USR",
  GET_ONE_MENU_USR_SUCCESS: "GET_ONE_MENU_USR_SUCCESS",
  GET_ONE_MENU_USR_FAILURE: "GET_ONE_MENU_USR_FAILURE",
  STORE_MENU_USR: 'STORE_MENU_USR',
  STORE_MENU_USR_SUCCESS: 'STORE_MENU_USR_SUCCESS',
  STORE_MENU_USR_FAILURE: 'STORE_MENU_USR_FAILURE',
  GET_PREV_MENUS_USR: 'GET_PREV_MENUS_USR',
  GET_PREV_MENUS_USR_SUCCESS: 'GET_PREV_MENUS_USR_SUCCESS',
  GET_PREV_MENUS_USR_FAILURE: 'GET_PREV_MENUS_USR_FAILURE',
};

export const VISITOR_REGISTER_TYPES = {
  GET_VISITOR_REGISTER: "GET_VISITOR_REGISTER",
  GET_VISITOR_REGISTER_SUCCESS: "GET_VISITOR_REGISTER_SUCCESS",
  GET_VISITOR_REGISTER_FAILURE: "GET_VISITOR_REGISTER_FAILURE",
  STORE_VISITOR_REGISTER: "STORE_VISITOR_REGISTER",
  STORE_VISITOR_REGISTER_SUCCESS: "STORE_VISITOR_REGISTER_SUCCESS",
  STORE_VISITOR_REGISTER_FAILURE: "STORE_VISITOR_REGISTER_FAILURE",
};

export const CONTACT_FORM_TYPES = {
  GET_CONTACT: "GET_CONTACT",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",
  STORE_TEXT_FORM: "STORE_TEXT_FORM",
  STORE_TEXT_FORM_SUCCESS: "STORE_TEXT_FORM_SUCCESS",
  STORE_TEXT_FORM_FAILURE: "STORE_TEXT_FORM_FAILURE",
};

export const AUTH_TYPES = {
  GET_DATA_USER: "GET_DATA_USER",
  GET_DATA_USER_SUCCESS: "GET_DATA_USER_SUCCESS",
  GET_DATA_USER_FAILURE: "GET_DATA_USER_FAILURE",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOGIN: "LOGIN",
  UPDATE_USER: "UPDATE_USER",
}

export const USER_TYPES = {
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  STORE_USER:'STORE_USER',
  STORE_USER_SUCCESS:'STORE_USER_SUCCESS',
  STORE_USER_FAILURE:'STORE_USER_FAILURE',
  GET_ONE_USER: "GET_ONE_USER",
  GET_ONE_USER_SUCCESS: "GET_ONE_USER_SUCCESS",
  GET_ONE_USER_FAILURE: "GET_ONE_USER_FAILURE",
}

export const INSTITUCIONALES_TYPES = {
  GET_INSTITUCIONALES: "GET_INSTITUCIONALES",
  GET_INSTITUCIONALES_SUCCESS: "GET_INSTITUCIONALES_SUCCESS",
  GET_INSTITUCIONALES_FAILURE: "GET_INSTITUCIONALES_FAILURE",
  STORE_INSTITUCIONAL:'STORE_INSTITUCIONAL',
  STORE_INSTITUCIONAL_SUCCESS:'STORE_INSTITUCIONAL_SUCCESS',
  STORE_INSTITUCIONAL_FAILURE:'STORE_INSTITUCIONAL_FAILURE',
  GET_ONE_INSTITUCIONAL: "GET_ONE_INSTITUCIONAL",
  GET_ONE_INSTITUCIONAL_SUCCESS: "GET_ONE_INSTITUCIONAL_SUCCESS",
  GET_ONE_INSTITUCIONAL_FAILURE: "GET_ONE_INSTITUCIONAL_FAILURE",
  DELETE_INSTITUCIONAL: "DELETE_INSTITUCIONAL",
  DELETE_INSTITUCIONAL_SUCCESS: "DELETE_INSTITUCIONAL_SUCCESS",
  DELETE_INSTITUCIONAL_FAILURE: "DELETE_INSTITUCIONAL_FAILURE",
  GET_STATES: "GET_STATES",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_FAILURE",
  GET_LIST_ACTIVE: "GET_LIST_ACTIVE_INSTITUTIONAL",
  GET_LIST_ACTIVE_SUCCESS: "GET_LIST_ACTIVE_INSTITUTIONAL_SUCCESS",
  GET_LIST_ACTIVE_FAILURE: "GET_LIST_ACTIVE_INSTITUTIONAL_FAILURE",
  POST_TEMPORAL_JSON: "POST_TEMPORAL_JSON",
  POST_TEMPORAL_JSON_SUCCESS: "POST_TEMPORAL_JSON_SUCCESS",
  POST_TEMPORAL_JSON_FAILURE: "POST_TEMPORAL_JSON_FAILURE",
}

export const HEADER_TYPES = {
  GET_HEADERS: 'GET_HEADERS',
  GET_HEADERS_SUCCESS: 'GET_HEADERS_SUCCESS',
  GET_HEADERS_FAILURE: 'GET_HEADERS_FAILURE',
  STORE_HEADER: 'STORE_HEADER',
  STORE_HEADER_SUCCESS: 'STORE_HEADER_SUCCESS',
  STORE_HEADER_FAILURE: 'STORE_HEADER_FAILURE',
  GET_ONE_HEADER: 'GET_ONE_HEADER',
  GET_ONE_HEADER_SUCCESS: 'GET_ONE_HEADER_SUCCESS',
  GET_ONE_HEADER_FAILURE: 'GET_ONE_HEADER_FAILURE',
  GET_PREV_HEADERS: 'GET_PREV_HEADERS',
  GET_PREV_HEADERS_SUCCESS: 'GET_PREV_HEADERS_SUCCESS',
  GET_PREV_HEADERS_FAILURE: 'GET_PREV_HEADERS_FAILURE',
  GET_LIST_ACTIVE: 'GET_LIST_ACTIVE_HEADER',
  GET_LIST_ACTIVE_SUCCESS: 'GET_LIST_ACTIVE_HEADER_SUCESS',
  GET_LIST_ACTIVE_FAILURE: 'GET_LIST_ACTIVE_HEADER_FAILURE',
  GET_TEXT_HEADER: "GET_TEXT_HEADER",
  GET_TEXT_HEADER_SUCCESS: "GET_TEXT_HEADER_SUCCESS",
  GET_TEXT_HEADER_FAILURE: "GET_TEXT_HEADER_FAILURE",
  STORE_TEXT_HEADER: "STORE_TEXT_HEADER",
  STORE_TEXT_HEADER_SUCCESS: "STORE_TEXT_HEADER_SUCCESS",
  STORE_TEXT_HEADER_FAILURE: "STORE_TEXT_HEADER_FAILURE",
};

export const LOGO_TYPES = {
  GET_LOGO: 'GET_LOGO',
  GET_LOGO_SUCCESS: 'GET_LOGO_SUCCESS',
  GET_LOGO_FAILURE: 'GET_LOGO_FAILURE',
  STORE_LOGO: 'STORE_LOGO',
  STORE_LOGO_SUCCESS: 'STORE_LOGO_SUCCESS',
  STORE_LOGO_FAILURE: 'STORE_LOGO_FAILURE'
};

export const LOGO_FOOTER_TYPES = {
  GET_LOGO_FOOTER: 'GET_LOGO_FOOTER',
  GET_LOGO_FOOTER_SUCCESS: 'GET_LOGO_FOOTER_SUCCESS',
  GET_LOGO_FOOTER_FAILURE: 'GET_LOGO_FOOTER_FAILURE',
  STORE_LOGO_FOOTER: 'STORE_LOGO_FOOTER',
  STORE_LOGO_FOOTER_SUCCESS: 'STORE_LOGO_FOOTER_SUCCESS',
  STORE_LOGO_FOOTER_FAILURE: 'STORE_LOGO_FOOTER_FAILURE',
  GET_LOGO_FOOTER_CENTRO: 'GET_LOGO_FOOTER_CENTRO',
  GET_LOGO_FOOTER_CENTRO_SUCCESS: 'GET_LOGO_FOOTER_CENTRO_SUCCESS',
  GET_LOGO_FOOTER_CENTRO_FAILURE: 'GET_LOGO_FOOTER_CENTRO_FAILURE',
  STORE_LOGO_FOOTER_CENTRO: 'STORE_LOGO_FOOTER_CENTRO',
  STORE_LOGO_FOOTER_CENTRO_SUCCESS: 'STORE_LOGO_FOOTER_CENTRO_SUCCESS',
  STORE_LOGO_FOOTER_CENTRO_FAILURE: 'STORE_LOGO_FOOTER_CENTRO_FAILURE',
};

export const LOGO_MENU_USR_TYPES = {
  GET_LOGO_MENU_USR: 'GET_LOGO_MENU_USR',
  GET_LOGO_MENU_USR_SUCCESS: 'GET_LOGO_MENU_USR_SUCCESS',
  GET_LOGO_MENU_USR_FAILURE: 'GET_LOGO_MENU_USR_FAILURE',
  STORE_LOGO_MENU_USR: 'STORE_LOGO_MENU_USR',
  STORE_LOGO_MENU_USR_SUCCESS: 'STORE_LOGO_MENU_USR_SUCCESS',
  STORE_LOGO_MENU_USR_FAILURE: 'STORE_LOGO_MENU_USR_FAILURE'
};

export const PORTADAS_TYPES = {
  GET_PORTADAS: 'GET_PORTADAS',
  GET_PORTADAS_SUCCESS: 'GET_PORTADAS_SUCCESS',
  GET_PORTADAS_FAILURE: 'GET_PORTADAS_FAILURE',
  GET_ONE_PORTADA: 'GET_ONE_PORTADA',
  GET_ONE_PORTADA_SUCCESS: 'GET_ONE_PORTADA_SUCCESS',
  GET_ONE_PORTADA_FAILURE: 'GET_ONE_PORTADA_FAILURE',
  STORE_PORTADA: 'STORE_PORTADA',
  STORE_PORTADA_SUCCESS: 'STORE_PORTADA_SUCCESS',
  STORE_PORTADA_FAILURE: 'STORE_PORTADA_FAILURE',
  GET_COMPONENT_TYPES: 'GET_COMPONENT_TYPES',
  GET_COMPONENT_TYPES_SUCCESS: 'GET_COMPONENT_TYPES_SUCCESS',
  GET_COMPONENT_TYPES_FAILURE: 'GET_COMPONENT_TYPES_FAILURE',
  GET_COMPONENTES: "GET_COMPONENTES",
  GET_COMPONENTES_SUCCESS: "GET_COMPONENTES_SUCCESS",
  GET_COMPONENTES_FAILURE: "GET_COMPONENTES_FAILURE",
  GET_ONE_COMPONENT: 'GET_ONE_COMPONENT',
  GET_ONE_COMPONENT_SUCCESS: 'GET_ONE_COMPONENT_SUCCESS',
  GET_ONE_COMPONENT_FAILURE: 'GET_ONE_COMPONENT_FAILURE',
  STORE_COMPONENT: 'STORE_COMPONENT',
  STORE_COMPONENT_SUCCESS: 'STORE_COMPONENT_SUCCESS',
  STORE_COMPONENT_FAILURE: 'STORE_COMPONENT_FAILURE',
  GET_LIST_ACTIVE: "GET_LIST_ACTIVE_PORTADAS",
  GET_LIST_ACTIVE_SUCCESS: "GET_LIST_ACTIVE_PORTADAS_SUCCESS",
  GET_LIST_ACTIVE_FAILURE: "GET_LIST_ACTIVE_PORTADAS_FAILURE",
};

export const AGRUPADORES_TYPES = {
  GET_AGRUPADORES: 'GET_AGRUPADORES',
  GET_AGRUPADORES_SUCCESS: 'GET_AGRUPADORES_SUCCESS',
  GET_AGRUPADORES_FAILURE: 'GET_AGRUPADORES_FAILURE',
  GET_ONE_AGRUPADOR: 'GET_ONE_AGRUPADOR',
  GET_ONE_AGRUPADOR_SUCCESS: 'GET_ONE_AGRUPADOR_SUCCESS',
  GET_ONE_AGRUPADOR_FAILURE: 'GET_ONE_AGRUPADOR_FAILURE',
  STORE_AGRUPADOR: 'STORE_AGRUPADOR',
  STORE_AGRUPADOR_SUCCESS: 'STORE_AGRUPADOR_SUCCESS',
  STORE_AGRUPADOR_FAILURE: 'STORE_AGRUPADOR_FAILURE',
  GET_FAQ_TYPES: 'GET_FAQ_TYPES',
  GET_FAQ_TYPES_SUCCESS: 'GET_FAQ_TYPES_SUCCESS',
  GET_FAQ_TYPES_FAILURE: 'GET_FAQ_TYPES_FAILURE',
  GET_FAQS: "GET_FAQS",
  GET_FAQS_SUCCESS: "GET_FAQS_SUCCESS",
  GET_FAQS_FAILURE: "GET_FAQS_FAILURE",
  GET_ONE_FAQ: 'GET_ONE_FAQ',
  GET_ONE_FAQ_SUCCESS: 'GET_ONE_FAQ_SUCCESS',
  GET_ONE_FAQ_FAILURE: 'GET_ONE_FAQ_FAILURE',
  STORE_FAQ: 'STORE_FAQ',
  STORE_FAQ_SUCCESS: 'STORE_FAQ_SUCCESS',
  STORE_FAQ_FAILURE: 'STORE_FAQ_FAILURE',
};


export const PANEL_USER_TYPES = {
  GET_PANEL_USER: 'GET_PANEL_USER',
  GET_PANEL_USER_SUCCESS: 'GET_PANEL_USER_SUCCESS',
  GET_PANEL_USER_FAILURE: 'GET_PANEL_USER_FAILURE',
  GET_ONE_PANEL_USER: 'GET_ONE_PANEL_USER',
  GET_ONE_PANEL_USER_SUCCESS: 'GET_ONE_PANEL_USER_SUCCESS',
  GET_ONE_PANEL_USER_FAILURE: 'GET_ONE_PANEL_USER_FAILURE',
  DELETE_PANEL_USER: 'DELETE_PANEL_USER',
  DELETE_PANEL_USER_SUCCESS: 'DELETE_PANEL_USER_SUCCESS',
  DELETE_PANEL_USER_FAILURE: 'DELETE_PANEL_USER_SUCCESS',
  STORE_PANEL_USER: 'STORE_PANEL_USER',
  STORE_PANEL_USER_SUCCESS: 'STORE_PANEL_USER_SUCCESS',
  STORE_PANEL_USER_FAILURE: 'STORE_PANEL_USER_FAILURE'
};
