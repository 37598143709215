import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import RestrictedRoute from 'src/components/shared/restrictedRoute';

import { getAllPanelUser, getOnePanelUser, deletePanelUser, storePanelUser } from 'src/actions/panelUserActions';
import Loading from 'src/components/shared/loading';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';
import PanelUser from 'src/components/panelUser/PanelUser';
import { FORM_ACTIONS } from 'src/utils/constants/general';
import FormPanelUser from 'src/components/panelUser/FormPanelUser';
import { getListActive as getListActiveInstitucionales } from 'src/actions/paginasInstitucionalesActions';
import { getListActive as getListActivePortadas } from 'src/actions/portadasActions';

const PanelUserContainer = (props) => {
  const {
    history,
    location,
    match,
    panelUser,
    institucionales,
    portadas,
    user
  } = props;

  const panelUserRoutes = {
    index: `${routesApp.panelUser.path}`,
    add: `${routesApp.addPanelUser.path}`,
    edit: `${routesApp.editPanelUser.path}`,
  };

  const panelUserPermissions = {
    index: routesApp.panelUser.permission,
    add: routesApp.addPanelUser.permission,
    edit: routesApp.editPanelUser.permission,
  };

  const handleGetAllPanelUser = (page, rows) => {
    props.getAllPanelUser(page, rows, location.search);
  };

  const handleAddPanelUser = async (data) => {
    await props.storePanelUser(null, data);
    history.push(panelUserRoutes.index);
  };

  const handleUpdatePanelUser = async (data, id) => {
    await props.storePanelUser(id, data);
    history.push(panelUserRoutes.index);
  };

  const handleDeletePanelUser = (id) => {
    NotificationManager.info('Eliminando panel de usuario...', null, 1500);
    props.deletePanelUser(id);
    props.getAllPanelUser();
  };

  useEffect(() => {
    if (match.path === panelUserRoutes.index) {
      props.getAllPanelUser(1, 10, location.search);
    } else if (match.path === panelUserRoutes.edit) {
      props.getOnePanelUser(match.params.panelUserId);
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    } else if (match.path === panelUserRoutes.add) {
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    }
    // eslint-disable-next-line
  }, [match.path, location.search]);

  return (
    <Switch>
      <RestrictedRoute exact path={panelUserRoutes.index} permission={panelUserPermissions.index} authPermissions={user.permissions}>
        {(panelUser.all === null) ? (
          <Loading />
        ) : (
          <PanelUser
            user={user}
            panelesUser={panelUser.all}
            loading={panelUser.loading}
            getAllPanelesUser={handleGetAllPanelUser}
            deletePanelUser={handleDeletePanelUser}
          />
        )}
      </RestrictedRoute>
      <RestrictedRoute exact path={panelUserRoutes.edit} permission={panelUserPermissions.edit} authPermissions={user.permissions}>
        {(!panelUser.loading
          && Boolean(panelUser.one)
          && panelUser.one.id.toString() === match.params.panelUserId
        ) ? (
          <FormPanelUser
            action={FORM_ACTIONS.EDIT}
            errors={panelUser.errors}
            loading={panelUser.loading}
            panelUser={panelUser.one}
            handlePanelUser={handleUpdatePanelUser}
            paginasInst={institucionales}
            portadas={portadas}
          />
          ) : (
            <Loading />
          )}
      </RestrictedRoute>
      <RestrictedRoute exact path={panelUserRoutes.add} permission={panelUserPermissions.add} authPermissions={user.permissions}>
        <FormPanelUser
          action={FORM_ACTIONS.ADD}
          errors={panelUser.errors}
          loading={panelUser.loading}
          panelUser={{}}
          handlePanelUser={handleAddPanelUser}
          paginasInst={institucionales}
          portadas={portadas}
        />
      </RestrictedRoute>
    </Switch>
  );
};

const mapStateToProps = ({
  panelUser,
  auth,
  paginasInstitucionales,
  portadas
}) => {
  return {
    panelUser,
    institucionales: paginasInstitucionales.listActive,
    portadas: portadas.listActive,
    user: auth.data
  };
};

const mapDispatchToProps = {
  getAllPanelUser,
  getOnePanelUser,
  getListActiveInstitucionales,
  getListActivePortadas,
  storePanelUser,
  deletePanelUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelUserContainer);
