import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';

const SaveButton = ({ disabled, children }) => (
  <Button
    color="primary"
    disabled={disabled}
    type="submit"
    variant="contained"
    startIcon={<SaveIcon />}
  >
   {children}
  </Button>
);

SaveButton.propTypes = {
  disabled: PropTypes.bool
};

export default SaveButton;
