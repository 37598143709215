import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
  FormControl
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'src/hooks/useQuery';

import {PORTADAS_STATES} from 'src/utils/constants/api'
import SelectField from '../../shared/SelectField';
import { getDataSelected } from 'src/helpers/random';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusquedaPortada = ({ className, setActivePage, ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();

  const getEstadoOptions = () => {
    return [{value: "", label: "Seleccione un estado..."}, {value: PORTADAS_STATES.PUBLICADO, label: "Activo"}, {value: PORTADAS_STATES.BORRADOR, label: "Inactivo"}];
  };
  const EstadoOptions = [{value: "", label: "Seleccione un estado..."}, {value: PORTADAS_STATES.BORRADOR, label: "Inactivo"}, {value: PORTADAS_STATES.PUBLICADO, label: "Activo"}];

  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              name: query.get('name_filter') ? query.get('name_filter') : '',
              slug: query.get('slug_filter') ? query.get('slug_filter') : '',
              active: query.get('front_page_state_id_filter') ? EstadoOptions[query.get('front_page_state_id_filter')] : ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255, 'El nombre no debe superar los 255 caracteres'),
              slug: Yup.string().max(255, 'El slug no debe superar los 255 caracteres'),
              active: Yup.object()
            })}
            onSubmit={async (data) => {
              setActivePage(1)
              query.set('name_filter', data.name);
              query.set('slug_filter', data.slug);
              query.set('front_page_state_id_filter', data.active ? data.active.value : "");
              history.push(`?${query.toString()}`);
            }}
            onReset={(data, actions) => {
              actions.setValues({
                name: '',
                slug: '',
                active: '1'
              });
              query.set('name_filter', '');
              query.set('slug_filter', '');
              query.set('front_page_state_id_filter', '');
              history.push(`?${query.toString()}`);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <Form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                  <Grid xs={12} md={4} className="p-2" item>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      fullWidth
                      label="Nombre"
                      name="name"
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                  <Grid xs={12} md={4} className="p-2" item>
                    <TextField
                      error={Boolean(touched.slug && errors.slug)}
                      helperText={touched.slug && errors.slug}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.slug}
                      fullWidth
                      label="Slug"
                      name="slug"
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className="p-2">
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <SelectField
                          placeholder="Seleccione un estado..."
                          name="active"
                          getOptions={getEstadoOptions}
                          label="Estado"
                          setFieldValue={(value) => {
                            setFieldValue('active', value);
                          }}
                          value={values.active}
                        />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} className="pt-2 pl-2 text-right">
                    <Button
                      className={classes.importButton}
                      onClick={handleReset}
                      type="button"
                    >
                      Limpiar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusquedaPortada;
