import React from 'react'
import {
  FormControl, FormHelperText, Grid, TextField, Typography
} from '@material-ui/core';
import { ChromePicker } from 'react-color';
import CKEditor from 'ckeditor4-react';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import ItemBtns from './agrupadors-item-btns';

import configCKEditor from 'src/config/ckeditor';
import Switch from '@material-ui/core/Switch';


const CKEditorConfigForItemText = {
  height: 150,
  font_names: 'Encode Sans, Arial',
  toolbar: [
    { name: 'basicstyles', items: ['Bold', 'Italic'] },
    { name: 'styles', items: ['TextColor', 'FontSize', 'Font'] },
    { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
    { name: 'links', items: ['Link', 'Unlink'] },
    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
  ],
}

const SubformItemsAgrupadorItem = ({ index, touched, errors, values, swap, remove, handleBlur, handleChange, setFieldValue, setFieldTouched }) =>
{
  const textErrorShouldShow = Boolean((touched.item && errors.item) && touched.item[index]?.text && errors.item[index]?.text);
  const divURLErrorShouldShow = Boolean((touched.item && errors.item) && touched.item[index]?.divURL && errors.item[index]?.divURL);
  const iconURLErrorShouldShow = Boolean((touched.item && errors.item) && touched.item[index]?.iconURL && errors.item[index]?.iconURL);

  return (
  <Grid container key={index}>
    <Grid item xs={12} md={6} className="px-3 py-3 my-2 border">

      <div>
        <h6>
          ITEM {index+1}
        </h6>
        <ItemBtns
          index={index}
          swap={swap}
          remove={remove}
          values={values}
        />
      </div>

      {/* ▼ TEXTO DEL ITEM */}
      <Typography color="textPrimary" variant="overline">Texto</Typography>
      <CKEditor
        id={`item.${index}.text`}
        name={`item.${index}.text`}
        initData={values.item[index].text}
        config={{...configCKEditor, ...CKEditorConfigForItemText}}
        onNamespaceLoaded={(CKEDITOR) => {}}
        onInstanceReady={(e) => { }}
        onChange={(e) =>
        {
            setTimeout(()=>{setFieldValue(`item.${index}.text`, e.editor.editable().getData())}, 0);
        }}
        onBlur={(e) => { setFieldTouched(`item.${index}.text`); }}
        onError={(e) => showDeveloperMessageConsole('Error: ', e)}
      />
      <FormHelperText className="text-danger">
        {textErrorShouldShow && errors.item[index]?.text}
      </FormHelperText>

      {/* ▼ Warn condicional si se superponen links en text y div */}
      {(values.item[index].divURL && values.item[index].text.includes("<a href=")) &&
      <div class="alert alert-warning d-flex mb-0" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="60" fill="currentColor" class="bi bi-exclamation-triangle-fill mr-3" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
        <p className='mb-0'>
          El usuario final no tendrá una forma accesible de diferenciar el enlace del texto del CKEditor del enlace asociado al contenedor del item.
        </p>
      </div> }

      {/* ▼ ENLACE para el contenedor del item. */}
      <TextField
        error={divURLErrorShouldShow}
        fullWidth
        helperText={divURLErrorShouldShow && errors.item[index]?.divURL}
        label="Enlace para contenedor del item."
        margin="normal"
        name={`item.${index}.divURL`}
        onBlur={handleBlur}
        onChange={e => {setFieldValue(e.target.name, e.target.value.trim())}}
        type="text"
        value={values.item[index].divURL}
        variant="outlined"
      />
      {/* ▼ TOOGLER p/abrir enlace en otra pestaña */}
      <Switch
        checked={values.item[index].willTargetBlank}
        onChange={handleChange}
        name={`item.${index}.willTargetBlank`}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <h6 className='d-inline'>Abrir URL en pestaña nueva</h6>

      {/* ▼ ENLACE para icono de descarga del item. */}
      <TextField
        error={iconURLErrorShouldShow}
        fullWidth
        helperText={iconURLErrorShouldShow && errors.item[index]?.iconURL}
        label="Enlace icono de descarga"
        margin="normal"
        name={`item.${index}.iconURL`}
        onBlur={handleBlur}
        onChange={e => {setFieldValue(e.target.name, e.target.value.trim())}}
        type="text"
        value={values.item[index].iconURL}
        variant="outlined"
      />

      {/* ▼ COLOR DE FONDO EN DIV DEL ITEM */}
      <Grid container>
        <Grid item xs={12} md={6} className="py-2">
          <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
            <ChromePicker
              width="60%"
              color={values.item[index].bgColor || '#D6D8D9'}
              onChangeComplete={(color) => setFieldValue(`item.${index}.bgColor`, color.hex)}
            />
          </FormControl>
        </Grid>
      </Grid>

    </Grid>
  </Grid>
  )
}

export default SubformItemsAgrupadorItem;
