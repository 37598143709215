import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Footers from 'src/components/footers/footers';
import {
  getAllFooters, getTextFooter, storeTextFooter, getLogo, storeLogo, getLogoCentro, storeLogoCentro, getOneFooter, storeFooter,
  getTextFooter2,
  storeTextFooter2
} from 'src/actions/footersActions';
import Loading from 'src/components/shared/loading';
import { routesApp } from 'src/routesService';
import TextFooter from 'src/components/footers/TextFooter';
import LogoFooter from 'src/components/footers/LogoFooter';
import { NotificationManager } from 'react-notifications';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import AddFooter from 'src/components/footers/AddFooter';
import EditFooter from 'src/components/footers/EditFooter';
import { getListActive } from 'src/actions/paginasInstitucionalesActions';
import { getListActive as getListActivePortadas } from 'src/actions/portadasActions';
import FooterService from '../services/FootersService';
import TextFooter2 from 'src/components/footers/TextFooter2';

const FootersContainer = (props) => {
  const {
    auth, footers, match, history, location, paginasInstitucionales, portadas
  } = props;

  const handleUpdateTextFooter = (data) => {
    props.storeTextFooter(data);
  };

  const handleUpdateTextFooter2 = (data) => {
    props.storeTextFooter2(data);
  };

  const handleUpdateLogo = (data) => {
    props.storeLogo(data);
  };

  const handleGetAllFooters = (page, rows) => {
    props.getAllFooters(page, rows, location.search);
  };

  const handleAddFooter = (data) => {
    props.storeFooter(null, data, history);
  };

  const handleUpdateFooter = (data, id) => {
    props.storeFooter(id, data, history);
  };

  const handleDeleteFooter = async (id) => {
    NotificationManager.info('Eliminando pie de página...', null, 1500);
    try {
      await FooterService.deleteFooter(id);
      await props.getAllFooters();
      NotificationManager.success('Pie de página eliminado correctamente');
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar pie de página: ', error);
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.');
    }
  };

  useEffect(() => {
    if (match.path === routesApp.logoFooter.path) {
      props.getLogo();
    } else if (match.path === routesApp.logoFooterDerecha.path) {
      props.getLogoCentro();
    } else if (match.path === routesApp.footers.path) {
      props.getAllFooters(1, 10, location.search);
    } else if (match.path === routesApp.editFooter.path) {
      props.getOneFooter(match.params.footerId);
      props.getListActive();
      props.getListActivePortadas();
    } else if (match.path === routesApp.addFooter.path) {
      props.getListActive();
      props.getListActivePortadas();
    }
    // eslint-disable-next-line
  }, [match.path, location.search]);

  if (match.path === routesApp.logoFooter.path) {
    if (footers.logo === null) {
      return <Loading />;
    }
    return (
      <LogoFooter
        auth={auth.data}
        logo={footers.logo}
        errors={footers.errors}
        name="Logo de Pie de Página"
        updateLogo={handleUpdateLogo}
      />
    )
  }

  if (match.path === routesApp.logoFooterDerecha.path) {
    if (footers.logo_centro === null) {
      return <Loading />;
    }
    return (
      <LogoFooter
        auth={auth.data}
        logo={footers.logo_centro}
        errors={footers.errors}
        name="Logo de Pie de Página Derecha"
        updateLogo={props.storeLogoCentro}
        switchActive
      />
    );
  }

  if (match.path === routesApp.footerText.path) {
    return <TextFooter auth={auth.data} updateFooter={handleUpdateTextFooter} errors={footers.errors} loading={footers.loading} />;
  }

  if (match.path === routesApp.footerText2.path) {
    return <TextFooter2 auth={auth.data} updateFooter={handleUpdateTextFooter2} errors={footers.errors} loading={footers.loading} />;
  }

  if (match.path === routesApp.addFooter.path) {
    if (paginasInstitucionales.listActive === null || portadas.listActive === null) {
      return <Loading />
    }

    return (
      <AddFooter
        errors={footers.errors}
        loading={footers.loading}
        addFooter={handleAddFooter}
        paginasInst={paginasInstitucionales.listActive}
        portadas={portadas.listActive}
      />
    )
  }

  if (match.path === routesApp.editFooter.path) {
    if (footers.one !== null && footers.one.id.toString() === match.params.footerId && paginasInstitucionales.listActive !== null && portadas.listActive !== null) {
      return (
        <EditFooter
          footer={footers.one}
          errors={footers.errors}
          loading={footers.loading}
          updateFooter={handleUpdateFooter}
          paginasInst={paginasInstitucionales.listActive}
          portadas={portadas.listActive}
        />
      )
    }
    return <Loading />
  }

  if (match.path === routesApp.footers.path && footers.all === null) {
    return <Loading />;
  }

  return (
    <Footers
      auth={auth.data}
      footers={footers.all}
      loading={footers.loading}
      getAllFooters={handleGetAllFooters}
      deleteFooter={handleDeleteFooter}
    />
  );
};

const mapStateToProps = ({
  footers, auth, paginasInstitucionales, portadas
}) => {
  return {
    footers,
    auth,
    paginasInstitucionales,
    portadas
  };
};

const mapDispatchToProps = {
  getAllFooters,
  getTextFooter,
  storeTextFooter,
  getTextFooter2,
  storeTextFooter2,
  getLogo,
  storeLogo,
  storeLogoCentro,
  getLogoCentro,
  getOneFooter,
  storeFooter,
  getListActive,
  getListActivePortadas
};

export default connect(mapStateToProps, mapDispatchToProps)(FootersContainer);
