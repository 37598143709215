/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable quotes */
/* eslint-disable indent */
import React, { useState } from "react";
import * as Yup from 'yup';
import CKEditorBoton from "src/components/shared/CKEditor/CKEditorBoton";
import { Form, Formik } from "formik";
import { FormControl, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { getOptionsSize } from "src/helpers/components";
import { routesApp } from "src/routesService";
import { SketchPicker } from "react-color";
import { URLRegex } from "src/helpers/random";
import { useRouteMatch } from "react-router-dom";
import { BackButton, SaveButton } from "src/components/shared/Buttons";

import SelectField from 'src/components/shared/SelectField';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';



export default function formConjuntoNoticias(props)
{
    const {
        componentType,
        component,
        loading,
        updateComponent,
        addComponent,
        filas,
        addFila,
    } = props;
    const [ maxSize, setMaxSize ] = useState(component ? getOptionsSize(component.col) : []);
    const match = useRouteMatch();

    return (
<Formik
    initialValues={{
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
        bg_color: component ? component.json_data?.bg_color : "#0072BB",
        
    }}
    validationSchema={Yup.object().shape({
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
        bg_color: Yup.string().required('El color del fondo es requerido'),
       
    })}
    onSubmit={async (data) =>
    {
        const formData = new FormData();

        formData.append('front_page_id', match.params.portadaId);
        formData.append('component_type_id', componentType.value);

        formData.append('row', data.fila.value)
        formData.append('col', data.columna.value)
        formData.append('size', data.tamanio.value)
        formData.append('json_data', JSON.stringify({
            bg_color: data.bg_color,
            hover_color: data.hover_color,
        }));

        if (component) {
            await updateComponent(formData, component.id);
        } else {
            await addComponent(formData);
        }
    }}
>
    {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
        isSubmitting,
    }) => (
    <Form className="w-100">
        <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">

                { /* UBICACIÓN */ }
                <Typography color="textPrimary" variant="overline">UBICACIÓN</Typography>

                {/* DROPDOWN FILA */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                    <SelectField
                    id="fila-titulo"
                    name="fila"
                    placeholder="Seleccione una fila..."
                    getOptions={() => filas}
                    label="Fila"
                    setFieldValue={(value) => {
                        setFieldValue('fila', value);
                        setFieldValue('columna', '');
                        setFieldValue('tamanio', '');
                    }}
                    value={values.fila}
                    addNewOption
                    addOption={() => addFila()}
                    />
                    <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
                </FormControl>

                {/* DROPDOWN COLUMNA */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                    <SelectField
                    id="columna-titulo"
                    name="columna"
                    placeholder="Seleccione una columna..."
                    getOptions={() => TAMANIOS_COMPONENTES_OPTIONS}
                    label="Columna"
                    setFieldValue={(value) => {
                        setFieldValue('columna', value);
                        setFieldValue('tamanio', '');
                        setMaxSize(getOptionsSize(value.value))
                    }}
                    value={values.columna}
                    />
                    <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
                </FormControl>

                {/* DROPDOWN P/ANCHO EN COLUMNAS DEL COMPONENTE */}
                <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                    <SelectField
                    id="tamanio-titulo"
                    name="tamanio"
                    placeholder="Seleccione un tamaño..."
                    getOptions={() => maxSize}
                    label="Tamaño"
                    setFieldValue={(value) => {
                        setFieldValue('tamanio', value);
                    }}
                    value={values.tamanio}
                    />
                    <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
                </FormControl>

                { /* BACKGROUND */ }
                <Typography color="textPrimary" variant="overline">BACKGROUND</Typography>
                <div style={{
                    display: "flex",
                    gap: "2rem",
                }}>
                    <FormControl margin="normal" fullWidth error={Boolean((touched.bg_color && errors.bg_color))}>
                        <Typography color="textSecondary" variant="overline">Fondo del botón</Typography>
                        <SketchPicker
                            width="100%"
                            color={values.bg_color}
                            onChangeComplete={(color) => setFieldValue('bg_color', color.hex)}
                        />
                        <FormHelperText className="text-danger">{(touched.bg_color && errors.bg_color) && errors.bg_color}</FormHelperText>
                    </FormControl>


                </div>

            </Grid>

            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                <BackButton disabled={isSubmitting} to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)} />
                <SaveButton disabled={isSubmitting} >Guardar</SaveButton>
            </Grid>

        </Grid>
    </Form>
    )}
</Formik>
    )
}
