import React, { useState } from 'react';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Switch,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import { ChromePicker } from 'react-color';

import { routesApp } from 'src/routesService';
import { URLRegex } from 'src/helpers/random';

import SelectField from 'src/components/shared/SelectField';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import { getOptionsSize } from 'src/helpers/components';
import SubformItemsSlider from './subform-items-slider';

const theme = createTheme({
  palette: {
    secondary: red
  }
});

const useStyles = makeStyles(_theme => ({
  color: {
    margin: _theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

export const itemInitialValuesImageStyles = {
  borderRadiusProp: 0,
  widthProp: 250,
  heightProp: 250,
  positionXProp: 0.5,
  positionYProp: 0.5,
  rotateProp: 0,
  scaleProp: 1
};

export const itemInitialValues = {
  titulo: '',
  copete: '',
  orden: '',
  enlace: '',
  bg_color: '#2EB5E6',
  image: '', //`${process.env.REACT_APP_BASE_URL_API}/partialFileName`
  partialFileName: '',
  alt_text: '',
  filename: '',
  imageStyles: itemInitialValuesImageStyles,
  editorRefImage: React.createRef(),
  editorSetMainImageFileTouched: false
};

const transformarItems = items => {
  for (const item of items) {
    item.titulo = item.titulo || '';
    item.copete = item.copete || '';
    item.orden = item.orden || '';
    item.enlace = item.enlace || '';
    item.bg_color = item.bg_color || '';
    item.image = item.partialFileName
      ? `${process.env.REACT_APP_BASE_URL_API}/${item.partialFileName}`
      : '';
    item.partialFileName = item.partialFileName || '';
    item.alt_text = item.alt_text || '';
    item.filename = item.filename || '';
    item.imageStyles = item.imageStyles || '';
    item.editorRefImage = React.createRef();
    item.editorSetMainImageFileTouched = false;
    // item.imageStyles = item.imageStyles
    //   ? JSON.parse(item.imageStyles)
    //   : itemInitialValuesImageStyles;
  }
  return items;
};

const validationSchema = Yup.object({
  fila: Yup.string().required('El número de fila es requerido'),
  columna: Yup.string().required('El número de columna es requerido'),
  tamanio: Yup.string().required('El tamaño es requerido'),
  // title: Yup.string().required('El título es requerido'),
  // link: Yup.string()
  //   .nullable()
  //   .test('valid-url', 'La URL ingresada es inválida', str =>
  //     str === null || str === undefined ? true : !!URLRegex.test(str)
  //   ),
  item: Yup.array().of(
    Yup.object().shape({
      // titulo: Yup.string().required('El texto del item es requerido'),
      // enlace: Yup.string()
      //   .nullable()
      //   .test('valid-url', 'La URL ingresada es inválida', str =>
      //     str === null || str === undefined ? true : !!URLRegex.test(str)
      //   ),
    })
  )
});

const FormSlider = props => {
  const classes = useStyles();
  const match = useRouteMatch();
  const [formikValidation, setFormikValidation] = useState(validationSchema);

  const {
    componentType,
    component,
    loading,
    filas,
    addFila,
    updateComponent,
    addComponent,
    appendListaImagenes
  } = props;

  const [maxSize, setMaxSize] = useState(
    component ? getOptionsSize(component.col, 2) : []
  );

  const initialValues = {
    fila: component ? { label: component.row, value: component.row } : '',
    columna: component ? { label: component.col, value: component.col } : '',
    tamanio: component ? { label: component.size, value: component.size } : '',
    // title: component ? component.title : '',
    link: component ? component.url || '' : '',
    es_destacado_principal: component
      ? component.json_data.es_destacado_principal
      : false,
    item: component
      ? transformarItems(component.json_data.listItems)
      : [itemInitialValues]
  };

  // ▼ opciones sin col 4, necesario por el ancho minimo de 2 col
  const posibleColumnOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 }
  ];

  const activarValidacionesParaDestacado = formikValidation => {
    setFormikValidation(validationSchema);
    // console.log('formikValidation', formikValidation);
  };

  const desactivarValidacionesParaDestacado = () => {
    const newFormikValidation = Yup.object({});
    setFormikValidation(newFormikValidation);
    // console.log('formikValidation', formikValidation);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formikValidation}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (data, actions) => {
        console.log('form-slider submit', data);

        const formData = new FormData();
        formData.append('front_page_id', match.params.portadaId);
        formData.append('component_type_id', componentType.value);
        if (!data.es_destacado_principal) {
          formData.append('row', data.fila.value);
          formData.append('col', data.columna.value);
          formData.append('size', data.tamanio.value);
        } else {
          formData.append('row', null);
          formData.append('col', null);
          formData.append('size', null);
        }
        // formData.append('title', data.title);
        formData.append('url', (data.link || '').trim());
        appendListaImagenes(data, formData);

        const jsonObject = {
          es_destacado_principal: data.es_destacado_principal,
          listItems: data.item
        };
        const stringifiedJSONobject = JSON.stringify(jsonObject);
        formData.append('json_data', stringifiedJSONobject);

        if (component) {
          await updateComponent(formData, component.id);
        } else {
          await addComponent(formData);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        resetForm,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              {/* DROPDOWN FILA */}
              {!values.es_destacado_principal && (
                <FormControl
                  margin="normal"
                  fullWidth
                  error={Boolean(touched.fila && errors.fila)}
                >
                  <SelectField
                    id="fila-titulo"
                    name="fila"
                    placeholder="Seleccione una fila..."
                    getOptions={() => filas}
                    label="Fila"
                    setFieldValue={value => {
                      setFieldValue('fila', value);
                      setFieldValue('columna', '');
                      setFieldValue('tamanio', '');
                    }}
                    value={values.fila}
                    addNewOption
                    addOption={() => addFila()}
                  />
                  <FormHelperText className="text-danger">
                    {touched.fila && errors.fila && errors.fila}
                  </FormHelperText>
                </FormControl>
              )}

              {/* DROPDOWN COLUMNA */}
              {!values.es_destacado_principal && (
                <FormControl
                  margin="normal"
                  fullWidth
                  error={Boolean(touched.columna && errors.columna)}
                >
                  <SelectField
                    id="columna-titulo"
                    name="columna"
                    placeholder="Seleccione una columna..."
                    getOptions={() => posibleColumnOptions}
                    label="Columna"
                    setFieldValue={value => {
                      setFieldValue('columna', value);
                      setFieldValue('tamanio', '');
                      setMaxSize(getOptionsSize(value.value, 2));
                    }}
                    value={values.columna}
                  />
                  <FormHelperText className="text-danger">
                    {touched.columna && errors.columna && errors.columna}
                  </FormHelperText>
                </FormControl>
              )}

              {/* DROPDOWN P/ANCHO EN COLUMNAS DEL COMPONENTE */}
              {!values.es_destacado_principal && (
                <FormControl
                  margin="normal"
                  fullWidth
                  error={Boolean(touched.tamanio && errors.tamanio)}
                >
                  <SelectField
                    id="tamanio-titulo"
                    name="tamanio"
                    placeholder="Seleccione un tamaño..."
                    getOptions={() => maxSize}
                    label="Tamaño"
                    setFieldValue={value => {
                      setFieldValue('tamanio', value);
                    }}
                    value={values.tamanio}
                  />
                  <FormHelperText className="text-danger">
                    {touched.tamanio && errors.tamanio && errors.tamanio}
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>
          </Grid>

          {/* ▼ Warn condicional si se superponen links en text y div */}
          {values.link && values.title.includes('<a href=') && (
            <div class="alert alert-warning d-flex mb-0 w-50" role="alert">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="60"
                fill="currentColor"
                class="bi bi-exclamation-triangle-fill mr-3"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <p className="mb-0">
                El usuario final no tendrá una forma accesible de diferenciar el
                enlace del texto del CKEditor del enlace asociado al contenedor
                del Título.
              </p>
            </div>
          )}

          {/* SWITCH PARA INDICAR SI ES DESTACADO PRINCIPAL */}
          <Switch
            checked={values.es_destacado_principal}
            onChange={evento => {
              handleChange(evento);
              if (evento.target.checked) {
                desactivarValidacionesParaDestacado(formikValidation);
              } else {
                activarValidacionesParaDestacado(formikValidation);
              }
            }}
            name={'es_destacado_principal'}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <h6 className="d-inline">Destacado Principal</h6>

          {/* ITEMS DE LA LISTA DEL AGRUPADOR */}
          <FieldArray
            name="item"
            render={({ push, remove, swap }) => (
              <SubformItemsSlider
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                resetForm={resetForm}
                touched={touched}
                errors={errors}
                values={values}
                push={push}
                swap={swap}
                remove={remove}
              />
            )}
          />

          {/* BOTONES ATRAS Y GUARDAR */}
          <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
            <ThemeProvider theme={theme}>
              <Link
                to={routesApp.componentes.path.replace(
                  ':portadaId',
                  match.params.portadaId
                )}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<BackIcon />}
                  className={classes.color}
                >
                  Atrás
                </Button>
              </Link>
            </ThemeProvider>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FormSlider;
