import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import {
  Button, createTheme, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, makeStyles, Radio, RadioGroup, TextField, ThemeProvider, Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import CKEditorCopete from 'src/components/shared/CKEditor/CKEditorCopete';

import { routesApp } from 'src/routesService';
import { selectFileWithCKFinder, URLRegex } from 'src/helpers/random';
import { SketchPicker } from 'react-color'
import EditorImage from 'src/components/shared/EditorImage';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormDestacadoPrincipal = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const {
    componentType,
    component,
    loading,
    appendImage,
    appendBackground,
    updateComponent,
    addComponent,
    deleteImage,
  } = props;

  const [background, setBackground] = useState(component ? component.page_background ? (component.page_background.color_hex ? '2' : '1') : '1' : '1');
  const [mainImageFileTouched, setMainImageFileTouched] = React.useState(false);
  const [backgroundFileTouched, setBackgroundFileTouched] = React.useState(false);

  const editorRefImage = React.createRef()
  const editorRefImageBackground = React.createRef()
  return (
    <Formik
      initialValues={{
        title: component ? component.title : '',
        copete: component ? component.copete : '',
        image: component ? `${process.env.REACT_APP_BASE_URL_API}/${component.image_file?.filename}` : '',
        alt_text: component ? component.image_file?.alt_text : '',
        link: component ? component.url : '',
        color_background: component && component.page_background && component.page_background.color_hex ? component.page_background.color_hex : '',
        image_background: component && component.page_background && component.page_background.color_hex === null ? `${process.env.REACT_APP_BASE_URL_API}/${component.page_background.image_file?.filename}` : ''
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().nullable(),
        copete: Yup.string().nullable(),
        image: Yup.string().nullable(),
        link: Yup.string().nullable().test('valid-url', 'La URL ingresada es inválida',
          (str) => {
            if (str === null || str === undefined) {
              return true;
            }
            return !!URLRegex.test(str);
          }),
        alt_text: Yup.string().nullable(),
        // image_background: background === "1" && Yup.string().required('La imagen del background es requerida'),
        color_background: background === '2' && Yup.string().required('El color del background es requerido'),
      })}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (data, actions) => {
        const formData = new FormData();

        formData.append('front_page_id', match.params.portadaId);
        formData.append('component_type_id', componentType.value);

        formData.append('title', data.title);
        formData.append('copete', data.copete);
        formData.append('url', data.link);

        if (data.image) {
          appendImage(formData, data, editorRefImage.current, mainImageFileTouched);
          /*
            if (typeof data.image  === 'string') {
            const url = data.image.split('/');
            if (url[url.length - 1] !== 'undefined') {
              appendImage(formData, data, editorRefImage.current, mainImageFileTouched);
            }
          } else {
            appendImage(formData, data, editorRefImage.current, mainImageFileTouched);
          } */
        }
        appendBackground(formData, background, data, editorRefImageBackground.current, backgroundFileTouched);

        if (component) {
          await updateComponent(formData, component.id);
        } else {
          await addComponent(formData);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl margin="normal" fullWidth error={Boolean(touched.title && errors.title)}>
                <Typography color="textPrimary" variant="overline">Título</Typography>
                <CKEditorTitulo
                  id="ck-title-principal"
                  value={values.title}
                  name="title"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.title && errors.title)}</FormHelperText>
              </FormControl>
              <FormControl className="ckeditor-height" margin="normal" fullWidth error={Boolean(touched.copete && errors.copete)}>
                <Typography color="textPrimary" variant="overline">Copete</Typography>
                <CKEditorCopete
                  id="ck-copete-principal"
                  value={values.copete}
                  name="copete"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.copete && errors.copete)}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {
            process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
              ? (
                <EditorImage
                  canDelete
                  label="Imagen principal"
                  editorRef={editorRefImage}
                  borderRadiusProp={component && component.image_file && JSON.parse(component.image_file?.styles).borderRadius ? JSON.parse(component.image_file?.styles).borderRadius : 0}
                  widthProp={component && component.image_file && JSON.parse(component.image_file?.styles).width ? JSON.parse(component.image_file?.styles).width : 350}
                  heightProp={component && component.image_file && JSON.parse(component.image_file?.styles).height ? JSON.parse(component.image_file?.styles).height : 380}
                  positionXProp={component && component.image_file && JSON.parse(component.image_file?.styles).positionX ? JSON.parse(component.image_file?.styles).positionX : 0.5}
                  positionYProp={component && component.image_file && JSON.parse(component.image_file?.styles).positionY ? JSON.parse(component.image_file?.styles).positionY : 0.5}
                  rotateProp={component && component.image_file && JSON.parse(component.image_file?.styles).rotate ? JSON.parse(component.image_file?.styles).rotate : 0}
                  scaleProp={component && component.image_file && JSON.parse(component.image_file?.styles).scale ? JSON.parse(component.image_file?.styles).scale : 1}
                  handleBlur={handleBlur}
                  handleChange={(value) => setFieldValue('image', value)}
                  handleDelete={() => { setFieldValue('image', null); deleteImage(component?.image_file); }}
                  setFileTouched={setMainImageFileTouched}
                  touched={mainImageFileTouched}
                  errors={errors.image}
                  value={values.image}
                />
              )
              : (
                <Grid container>
                  <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                    <TextField
                      error={Boolean(errors.image && errors.image)}
                      fullWidth
                      helperText={touched.image && errors.image}
                      label="Imagen Destacado"
                      margin="normal"
                      name="image"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.image}
                      variant="outlined"
                      // disabled
                      InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image')}><CloudUploadIcon /></IconButton> }}
                    />
                  </Grid>
                </Grid>
              )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.alt_text && errors.alt_text)}
                fullWidth
                helperText={touched.alt_text && errors.alt_text}
                label="Texto alternativo de la imagen destacado"
                margin="normal"
                name="alt_text"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.alt_text}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.link && errors.link)}
                fullWidth
                helperText={touched.link && errors.link}
                label="Enlace"
                margin="normal"
                name="link"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.link}
                variant="outlined"
              />
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Background</Typography>

                <FormLabel component="legend">Tipo de background</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={background} onChange={handleChange}>
                  <FormControlLabel value="1" onChange={() => setBackground('1')} control={<Radio />} label="Imagen" />
                  <FormControlLabel value="2" onChange={() => setBackground('2')} control={<Radio />} label="Color" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {
            background === '1'
            && (
              process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
                ? (
                  <EditorImage
                    canDelete
                    label="Imagen del background"
                    editorRef={editorRefImageBackground}
                    borderRadiusProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).borderRadius ? JSON.parse(component.page_background.image_file?.styles).borderRadius : 0}
                    widthProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).width ? JSON.parse(component.page_background.image_file?.styles).width : 1110}
                    heightProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).height ? JSON.parse(component.page_background.image_file?.styles).height : 380}
                    positionXProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).positionX ? JSON.parse(component.page_background.image_file?.styles).positionX : 0.5}
                    positionYProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).positionY ? JSON.parse(component.page_background.image_file?.styles).positionY : 0.5}
                    rotateProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).rotate ? JSON.parse(component.page_background.image_file?.styles).rotate : 0}
                    scaleProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file?.styles).scale ? JSON.parse(component.page_background.image_file?.styles).scale : 1}
                    handleBlur={handleBlur}
                    handleChange={(value) => setFieldValue('image_background', value)}
                    handleDelete={() => { setFieldValue('image_background', null); deleteImage(component?.page_background?.image_file); }}
                    setFileTouched={setBackgroundFileTouched}
                    touched={backgroundFileTouched}
                    errors={errors.image_background}
                    value={values.image_background}
                  />
                )
                : (
                  <Grid container>
                    <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                      <TextField
                        error={Boolean(errors.image_background && errors.image_background)}
                        fullWidth
                        helperText={touched.image_background && errors.image_background}
                        label="Imagen Background"
                        margin="normal"
                        name="image_background"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.image_background}
                        variant="outlined"
                        // disabled
                        InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image_background')}><CloudUploadIcon /></IconButton> }}
                      />
                    </Grid>
                  </Grid>
                )
            )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              {
                background === '2'
                && (
                <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                  <SketchPicker
                    width="100%"
                    color={values.color_background}
                    onChangeComplete={(color) => setFieldValue('color_background', color.hex)}
                  />
                  <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                </FormControl>
                )
              }
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}

    </Formik>
  );
};

export default FormDestacadoPrincipal;
