import React from 'react'
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent:"center"
  },
});


const Home = () => {
  const classes=useStyles()
  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardContent className="text-center">
          <Typography >Backoffice administrativo del sitio institucional</Typography>
          {/* <Typography variant="h3" component="h2" className="mt-1">"Juana Manso"</Typography> */}
        </CardContent>
      </Card>
    </div>
  )
}

export default Home
