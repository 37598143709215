import React from 'react';
import SubformItemsSliderItem from './subform-items-slider-item';
import { itemInitialValues } from './form-slider';

const SubformItemsSlider = props => {
  const {
    values,
    errors,
    touched,
    swap,
    push,
    remove,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched
  } = props;

  return (
    <div>
      {values.item.map((itemdata, index) => (
        <SubformItemsSliderItem
          key={index}
          index={index}
          values={values}
          errors={errors}
          touched={touched}
          swap={swap}
          remove={remove}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      ))}

      {values.item.length < 30 && (
        <button
          type="button"
          className="add-item-agrupador"
          onClick={() => push(itemInitialValues)}
        >
          AÑADIR ITEM
        </button>
      )}
    </div>
  );
};

export default SubformItemsSlider;
