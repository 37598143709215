import React from 'react';
import logoEducar from '../../assets/img/logo_educar.svg';

const Logo = (props) => {
  return (
    <img
      alt="Logo Educar"
      src={logoEducar}
      className="logo-educar"
      {...props}
    />
  );
};

export default Logo;

