import React from "react";
import { ItemBtn } from "./agrupadors-item-btn";
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";

//estilo para el fondo del dialogo
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


export default function ItemBtns({ index, remove, swap, values }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const itemToDelete = index + 1;

  useStyles();
  
  const handleDelete = () => {
    remove(index);
    setShowConfirm(false);
  };

  return (
    <div className="agrupador-item-btns">

      <ItemBtn
        variant="move"
        disabled={index === 0}
        onClick={() => { swap(index, index - 1) }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>
      </ItemBtn>

      <ItemBtn
        variant="move"
        disabled={index + 1 === values.item.length}
        onClick={() => { swap(index, index + 1) }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </ItemBtn>

      <ItemBtn
        variant="delete"
        onClick={() => setShowConfirm(true)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
        </svg>
      </ItemBtn>

      <><Dialog open={showConfirm} onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setShowConfirm(false);
        }
      }}>
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
        <DialogContentText>
        ¿Estás seguro que deseas eliminar el <span style={{ fontWeight: 'bold' }}>Item {itemToDelete}</span> ?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)}>
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
    </div>
  )
}
