import React from 'react';
import { useDispatch } from 'react-redux';
// import ReactHtmlParser from 'react-html-parser'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { STATES_PAGINAS_INSTITUCIONALES, NAMES_PAGINAS_INSTITUCIONALES_STATES } from 'src/utils/constants/api';
import { convertDate, stripHTML } from 'src/helpers/random'
import TableHeader from 'src/components/shared/TableHeader';
import { generateTemporalPreview } from 'src/actions/paginasInstitucionalesActions';
import Actions from './Actions';

const TableNewsPages = ({
  className,
  loading,
  data,
  currentPage,
  itemsCountPerPage,
  totalItemsCount,
  prevPage,
  nextPage,
  lastPage,
  hasPermission,
  ...rest
}) => {
  const handlePageChange = (event, page) => {
    rest.getAllPaginas(page + 1, parseInt(itemsCountPerPage, 10))
  };

  const handleChangeRowsPerPage = (event) => {
    rest.getAllPaginas(1, event.target.value)
  };

  const dispatch = useDispatch();

  function uselessLink(event, pagina) {
    event.preventDefault();
    dispatch(generateTemporalPreview(pagina));
  }

  const showAction = Object.entries(hasPermission).some(([key, value]) => key !== 'add' && value);

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de Noticias
            {
              loading
              && <CircularProgress size={24} style={{ marginLeft: 15 }} />
            }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="id">ID</TableHeader>
                <TableHeader property="title">Título</TableHeader>
                <TableHeader property="post_date">Fecha Públicacion</TableHeader>
                <TableHeader property="updatedBy.username">Usuario Ult. Modificación</TableHeader>
                <TableHeader property="updated_at">Fecha Ult. Modificación</TableHeader>
                <TableHeader property="state.name">Estado</TableHeader>
                {
                  showAction
                  && <TableCell>Acciones</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.state.id === STATES_PAGINAS_INSTITUCIONALES.PUBLICADO ? <a href="#REA" onClick={(e) => uselessLink(e, item)} target="_blank" rel="noopener noreferrer">{stripHTML(item.title)}</a> : stripHTML(item.title)}</TableCell>
                  <TableCell>{item.post_date ? convertDate(item.post_date, 'YYYY-MM-DD', 'DD/MM/YYYY') : 'No publicado aún'}</TableCell>
                  <TableCell>{item.updated_by ? item.updated_by.username : ''}</TableCell>
                  <TableCell>{convertDate(item.updated_at, 'YYYY-MM-DD', 'DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    {
                      item.state.name === NAMES_PAGINAS_INSTITUCIONALES_STATES.BORRADOR
                      && <Chip label={item.state.display_name} style={{ backgroundColor: '#4A8FFB', color: '#fff' }} />
                    }
                    {
                      item.state.name === NAMES_PAGINAS_INSTITUCIONALES_STATES.PUBLICADO
                      && <Chip label={item.state.display_name} style={{ backgroundColor: '#00D014', color: '#fff' }} />
                    }
                    {
                      item.state.name === NAMES_PAGINAS_INSTITUCIONALES_STATES.DESPUBLICADO
                      && <Chip label={item.state.display_name} style={{ backgroundColor: '#FF3812', color: '#fff' }} />
                    }
                  </TableCell>
                  {
                    showAction
                    && (
                    <TableCell>
                      <Actions
                        pagina={item}
                        deletePagina={rest.deletePagina}
                        hasPermission={hasPermission}
                      />
                    </TableCell>
                    )
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0
        && (
        <div className="text-center mt-2">
          No hay resultados para su búsqueda
        </div>
        )
      }
      <TablePagination
        component="div"
        className="paginador"
        count={totalItemsCount ?? 0}
        // count={data.length}//hasta que venga la api
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage, 10)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default TableNewsPages;
