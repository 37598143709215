/* eslint-disable brace-style */
/* eslint-disable indent */
import { readFileForUpload } from 'src/utils/readFileForUpload';
import api from '../api';

const UploadService =
{
    list: (page, limit, filters) =>
    {
        return api.get(`/uploads?page=${page}&limit=${limit}&${filters}`);
    },
    create: (data) =>
    {
        return api.post('/uploads', data)
            .then(({ data: { id: uploadId } }) =>
            {
                return uploadId;
            });
    },
    get: (uploadId) =>
    {
        return api.get(`/uploads/${uploadId}`);
    },
    update: (uploadId, data) =>
    {
        return api.put(`/uploads/${uploadId}`, data);
    },
    upload: (uploadId, file) =>
    {
        return readFileForUpload(file, 1024 * 1024 * 5, (res, chunkSize, fileOffset, fileSize) =>
        {
            return api.post(`/uploads/${uploadId}`, res);
        });
    },
    delete: (uploadId) =>
    {
        return api.delete(`/uploads/${uploadId}`);
    },
    deleteFile: (uploadId) =>
    {
        return api.delete(`/uploads/${uploadId}/file`);
    }
}

export default UploadService;
