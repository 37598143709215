import React from 'react';
import CKEditorJuanaManso from '../CkeditorJuanaManso';
import { validateOnePermission } from 'src/helpers/permissions';
import { CKEDITOR_PERMISSIONS } from 'src/utils/constants/permissions';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers';

const CKEditorTexto = props => {
  const user = useSelector(selectUser);

  const sourceHTML = { name: 'source', items: ['Source'] };

  const configCkeditorTexto = {
    height: 400,
    toolbar: [
      { name: 'heading', items: ['Format'] },
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'styles', items: ['TextColor', 'BGColor', 'FontSize', 'Font'] },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          'HorizontalRule'
        ]
      },
      {
        name: 'alignment',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      // '/',
      {
        name: 'editing',
        items: [
          'Find',
          'Replace',
          'SelectAll',
          '-',
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo'
        ]
      },
      // validateOnePermission(user.permissions, CKEDITOR_PERMISSIONS.VIEW_SOURCE)
      //   ? sourceHTML
      //   : {}
      { name: 'source', items: ['Source'] },
     
    ]
  };

  return (
    <div>
      <CKEditorJuanaManso configParent={configCkeditorTexto} {...props} />
    </div>
  );
};

export default CKEditorTexto;
