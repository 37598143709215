import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListItem,
  Divider,
  IconButton
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  PeopleAlt as PeopleAltIcon,
  Security as SecurityIcon,
  Person as PersonIcon,
  ContactMail as ContactMailIcon,
  Pages as PagesContact,
  VerticalAlignTop as VerticalAlignTopIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
  Launch as LaunchIcon,
  List as ListIcon,
  TextFields as TextFieldsIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Visibility as VisitorIcon,
  RecentActors as MenuUsrIcon,
  RecentActors as PanelUserIcon,
  CloudUpload as PublishIcon
} from '@material-ui/icons';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// custom
import { routesApp } from 'src/routesService';
import {
  AGRUPADORES_PERMISSIONS,
  CONTACT_PERMISSIONS,
  CONTACT_MESSAGE_PERMISSIONS,
  FOOTER_TEXT_PERMISSIONS,
  FOOTER_TEXT_PERMISSIONS2,
  HEADER_TEXT_PERMISSIONS,
  LOGOS_PERMISSIONS,
  MENU_PERMISSIONS,
  PAGINAS_INSTITUCIONALES_PERMISSIONS,
  PANEL_USER_PERMISSIONS,
  PERMISSIONS_PERMISSIONS,
  PORTADAS_PERMISSIONS,
  ROLES_PERMISSIONS,
  USERS_PERMISSIONS,
  VISITOR_REGISTER_PERMISSIONS,
  NEWS_PAGE_PERMISSIONS,
  UPLOAD_PERMISSIONS
} from 'src/utils/constants/permissions';
import { validatePermissions } from 'src/helpers/permissions';

import NavItem from './NavItem';
import CaraJuanaManso from '../../../assets/img/icono_cara_jm.png';

const itemsNav = [
  /*   {
    name: "General",
    items: [
      {
        href: routesApp.inicio.path,
        icon: HomeIcon,
        title: 'Inicio'
      }
    ]
  }, */
  {
    name: 'Contenidos',
    items: [
      {
        href: routesApp.paginasInstitucionales.path,
        icon: PagesContact,
        title: 'Pág. Institucionales',
        permissions: PAGINAS_INSTITUCIONALES_PERMISSIONS
      },
      {
        href: routesApp.portadas.path,
        icon: PagesContact,
        title: 'Portadas',
        permissions: PORTADAS_PERMISSIONS
      },
      {
        href: routesApp.newsPage.path,
        icon: PagesContact,
        title: 'Noticias',
        permissions: NEWS_PAGE_PERMISSIONS
      }
    ]
  },
  {
    name: 'Configuración',
    items: [
      {
        href: routesApp.contactForm.path,
        icon: ContactMailIcon,
        title: 'Form. Contacto',
        permissions: CONTACT_PERMISSIONS
      },
      {
        href: routesApp.agrupadores.path,
        icon: QuestionAnswerIcon,
        title: 'FAQs',
        permissions: AGRUPADORES_PERMISSIONS
      },
      {
        href: routesApp.menuUsr.path,
        icon: MenuUsrIcon,
        title: 'Menú Usr',
        permissions: MENU_PERMISSIONS
      },
      {
        href: routesApp.panelUser.path,
        icon: PanelUserIcon,
        title: 'Panel User',
        permissions: PANEL_USER_PERMISSIONS
      },
      {
        icon: VerticalAlignTopIcon,
        title: 'Encabezado',
        permissions: { 
          ...MENU_PERMISSIONS, 
          ...LOGOS_PERMISSIONS, 
          ...HEADER_TEXT_PERMISSIONS 
        },
        subItems: [
          {
            href: routesApp.headers.path,
            icon: ListIcon,
            title: 'Menú de Navegación',
            permissions: MENU_PERMISSIONS
          },
          {
            href: routesApp.logoHeader.path,
            icon: LaunchIcon,
            title: 'Logo',
            permissions: LOGOS_PERMISSIONS
          },
          {
            href: routesApp.headerText.path,
            icon: TextFieldsIcon,
            title: 'Texto',
            permissions: HEADER_TEXT_PERMISSIONS
          }
        ]
      },
      {
        icon: VerticalAlignBottomIcon,
        title: 'Pie de página',
        permissions: {
          ...MENU_PERMISSIONS,
          ...FOOTER_TEXT_PERMISSIONS,
          ...FOOTER_TEXT_PERMISSIONS2,
          ...LOGOS_PERMISSIONS
        },
        subItems: [
          {
            href: routesApp.footers.path,
            icon: ListIcon,
            title: 'Menú de Navegación',
            permissions: MENU_PERMISSIONS
          },
          {
            href: routesApp.logoFooter.path,
            icon: LaunchIcon,
            title: 'Logo',
            permissions: LOGOS_PERMISSIONS
          },
          {
            href: routesApp.logoFooterDerecha.path,
            icon: LaunchIcon,
            title: 'Logo Footer Derecha',
            permissions: LOGOS_PERMISSIONS
          },
          {
            href: routesApp.footerText.path,
            icon: TextFieldsIcon,
            title: 'Texto',
            permissions: FOOTER_TEXT_PERMISSIONS
          },
          {
            href: routesApp.footerText2.path,
            icon: TextFieldsIcon,
            title: 'Texto2',
            permissions: FOOTER_TEXT_PERMISSIONS2
          }
        ]
      },
      {
        href: routesApp.files.path,
        icon: PublishIcon,
        title: 'Gestión de archivos',
        permissions: UPLOAD_PERMISSIONS
      }
    ]
  },
  {
    name: 'Seguridad',
    items: [
      {
        href: routesApp.users.path,
        icon: PersonIcon,
        title: 'Usuarios',
        permissions: USERS_PERMISSIONS
      },
      {
        href: routesApp.roles.path,
        icon: PeopleAltIcon,
        title: 'Roles',
        permissions: ROLES_PERMISSIONS
      },
      {
        href: routesApp.permissions.path,
        icon: SecurityIcon,
        title: 'Permisos',
        permissions: PERMISSIONS_PERMISSIONS
      },
      {
        href: routesApp.contactMessage.path,
        icon: TextFieldsIcon,
        title: 'Mensaje Contacto',
        permissions: CONTACT_MESSAGE_PERMISSIONS
      },
      {
        href: routesApp.registroVisitas.path,
        icon: VisitorIcon,
        title: 'Registro Visitas',
        permissions: VISITOR_REGISTER_PERMISSIONS
      }
    ]
  }
];

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: 64
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    position: 'relative',
    width: 256,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  avatar: {
    cursor: 'pointer',
    width: 70,
    height: 70
  }
}));

const NavBar = ({ onNavClose, navOpen, user }) => {
  const classes = useStyles();
  const location = useLocation();


  const content = (
    <List>
      <ListItem style={{ justifyContent: 'center' }}>
        <Box alignItems="center" display="flex" flexDirection="column" p={2}>
          <Avatar className='mb-2'>
            <AccountCircleIcon className='iconoUsuario' style={{ fontSize: 40 }}/>
          </Avatar>
          {navOpen && (
            <>
              <Typography
                className={classes.name}
                color="textPrimary"
                variant="h5"
              >
                {`${user.name} ${user.lastname}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ fontSize: '0.8rem' }}
              >
                {user.email}
              </Typography>
            </>
          )}
        </Box>
      </ListItem>

      {itemsNav.map((itemNav, index) => {
        return (
          <React.Fragment key={index}>
            {navOpen && (
              <div className="pl-3 mt-3">
                <Typography
                  color="textPrimary"
                  variant="overline"
                  className="font-weight-bold"
                >
                  {itemNav.name}
                </Typography>
              </div>
            )}
            {// eslint-disable-next-line
            itemNav.items.map((item, index) => {
              if (!item.permissions) {
                return (
                  <NavItem
                    key={index}
                    icon={item.icon}
                    href={item.href}
                    title={item.title}
                    subItems={item.subItems}
                  />
                );
              }
              if (
                item.permissions &&
                validatePermissions(user.permissions, item.permissions)
              ) {
                return (
                  <NavItem
                    key={index}
                    icon={item.icon}
                    href={item.href}
                    title={item.title}
                    subItems={item.subItems}
                  />
                );
              }
            })}
          </React.Fragment>
        );
      })}
      <Box flexGrow={1} />
    </List>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onNavClose}
          open={navOpen}
          variant="temporary"
          className="scrollbar-hidden"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          variant="permanent"
          classes={{
            paper: clsx(
              classes.desktopDrawer,
              !navOpen && classes.drawerPaperClose
            )
          }}
          onClose={onNavClose}
          open={navOpen}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={onNavClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.defaultProps = {
  onNavClose: () => {},
  navOpen: false
};

export default NavBar;
