import React from 'react';
import CKEditorJuanaManso from '../CkeditorJuanaManso';

const CKEditorBoton = (props) => {
  const configCKEditorBoton = {
    height: 80,
    toolbar: [
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'styles', items: ['TextColor', 'FontSize', 'Font'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'editing', items: ['Find', 'Replace', 'SelectAll', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      { name: 'insert', items: ['HorizontalRule'] },
      { name: 'source', items: ['Source'] }
    ],
  };

  return (
    <div>
      <CKEditorJuanaManso
        configParent={configCKEditorBoton}
        {...props}
      />
    </div>
  );
};

export default CKEditorBoton;
