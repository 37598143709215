import React from 'react';
import CKEditorJuanaManso from '../CkeditorJuanaManso';

const CKEditorTitulo = (props) => {
  const configCkeditorTitle = {
    height: 200,
    toolbar: [
      { name: 'heading', items: ['Format'] },
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'textstyles', items: ['TextColor', 'BGColor', 'Font'] },
      { name: 'editing', items: ['Find', 'Replace', 'SelectAll', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      { name: 'insert', items: ['HorizontalRule'] },
      { name: 'source', items: ['Source'] }
    ]
  };

  return (
    <div>
      <CKEditorJuanaManso
        configParent={configCkeditorTitle}
        {...props}
      />
    </div>
  );
};

export default CKEditorTitulo;
