/* eslint-disable array-bracket-spacing */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BackButton, SaveButton } from 'src/components/shared/Buttons';
import { routesApp } from 'src/routesService';
import UploadService from 'src/services/UploadService';
import UploadFileButton from 'src/components/shared/Buttons/UploadFileButton';
import { NotificationManager } from 'react-notifications';
import { CopiableTextField } from './CopiableTextField';

export function ArchivoEditor({ archivo })
{
    const [ file, setFile ] = React.useState(null);
    const [ isSubmitting, setSubmitting ] = React.useState(false);
    const [ progress, setProgress ] = React.useState(0);
    const history = useHistory();

    const modal = useRef()



    return (
<Formik
    initialValues={{
        name: archivo?.file_name ?? '',
        title: archivo?.title ?? '',
    }}
    validationSchema={Yup.object().shape({
        name: Yup.string().required('El archivo es requerido'),
        title: Yup.string().max(128, 'El nombre no debe superar los 128 caracteres').required('El título es requerido'),
    })}
    onSubmit={async (data) =>
    {
        setSubmitting(true)
        modal.current.showModal()
        setTimeout(() => {
            if (archivo == null)
            {
                UploadService.create({
                    title: data.title,
                    file_size: file.size,
                    file_name: file.name,
                    mime_type: file.type,
                })
                .then((uploadId) =>
                {
                    return UploadService.upload(uploadId, file).then(() => uploadId);
                })
                .then((uploadId) =>
                {
                    NotificationManager.success('El archivo fue creado correctamente');
                    modal.current.close()
                    history.push(routesApp.editFile.path.replace(':uploadId', uploadId));
                })
                .catch((error) =>
                {
                    console.error(error);
                    modal.current.close()
                    NotificationManager.error('Error al crear el archivo');
                });
            }
            else
            {
                UploadService.update(archivo.id, {
                    title: data.title,
                    file_size: file?.size,
                    file_name: file?.name,
                    mime_type: file?.type,
                })
                .then(() =>
                {
                    if(file)
                    return UploadService.deleteFile(archivo.id);
                })
                .then(() =>
                {
                    if(file)
                    return UploadService.upload(archivo.id, file);
                })
                .then(() =>
                {
                    NotificationManager.success('El archivo fue actualizado correctamente');
                    modal.current.close()
                })
                .catch((error) =>
                {
                    console.error(error);
                    modal.current.close()
                    NotificationManager.error('Error al actualizar el archivo');
                });
            }
            setSubmitting(false)
        }, 0)
    }}
>
    {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
    }) => (
        <Form>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container className="mb-3" spacing={2}>
                        <Grid item xs={12} md={9}>
                            <TextField
                                value={values.name}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                fullWidth
                                label="Nombre del archivo"
                                variant="outlined"
                                name="filename"
                                disabled={isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <UploadFileButton
                                accept={[
                                    'application/pdf'
                                ]}
                                label="Seleccionar archivo"
                                disabled={isSubmitting}
                                fullWidth
                                handleBlur={() =>
                                {
                                }}
                                handleFileChange={(newFile) =>
                                {
                                    if (newFile.type === 'application/pdf')
                                    {
                                        setFile(newFile);
                                        setFieldValue('name', !newFile ? '' : newFile.name);
                                    }
                                    else
                                    {
                                        alert('Sólo se permite subir archivos PDF');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} className="mb-2">
                            <TextField
                                value={values.title}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Título"
                                variant="outlined"
                                name="title"
                                disabled={isSubmitting}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item className="mb-4">
                        <TextField
                            name="id"
                            label="ID"
                            value={archivo == null ? '' : archivo.id}
                            disabled={archivo == null}
                            variant="filled"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item className="mb-4">
                        <CopiableTextField
                            name="url-preview"
                            label="URL de visualización"
                            value={archivo == null ? '' : archivo.preview_url}
                            disabled={archivo == null}
                            readOnly
                            fullWidth
                            copyTooltip="Copiar enlace"
                            copyText={(value) =>
                            {
                                navigator.clipboard.writeText(value);
                                NotificationManager.success('Enlace copiado correctamente');
                                modal.current.close()
                            }}
                        />
                    </Grid>
                    <Grid item className="mb-4">
                        <CopiableTextField
                            name="url-download"
                            label="URL de descarga"
                            value={archivo == null ? '' : archivo.download_url}
                            disabled={archivo == null}
                            readOnly
                            fullWidth
                            copyTooltip="Copiar enlace"
                            copyText={(value) =>
                            {
                                navigator.clipboard.writeText(value);
                                NotificationManager.success('Enlace copiado correctamente');
                                modal.current.close()
                           }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Submit */}
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                <BackButton disabled={isSubmitting} to={routesApp.files.path} />
                <SaveButton disabled={isSubmitting} >Subir archivo</SaveButton>
            </Grid>

            {/* MODAL P/BLOQUEAR APP DURANTE LA SUBIDA */}
            <dialog id="uploadingModal" ref={modal}>
                <h5>Aguarde mientras se completa la carga/actualización del archivo...</h5>
                <div> <CircularProgress /> </div>
            </dialog>

        </Form>
    )}
</Formik>
    )
}
