/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import NotFoundView from 'src/components/errors/NotFoundView';
import ForbiddenView from 'src/components/errors/ForbiddenView';

import {
  ROLES_PERMISSIONS,
  PAGINAS_INSTITUCIONALES_PERMISSIONS,
  USERS_PERMISSIONS,
  FOOTER_TEXT_PERMISSIONS,
  CONTACT_PERMISSIONS,
  MENU_PERMISSIONS,
  LOGOS_PERMISSIONS,
  PORTADAS_PERMISSIONS,
  COMPONENTES_PERMISSIONS,
  AGRUPADORES_PERMISSIONS,
  FAQS_PERMISSIONS,
  PANEL_USER_PERMISSIONS,
  VISITOR_REGISTER_PERMISSIONS,
  NEWS_PAGE_PERMISSIONS,
  UPLOAD_PERMISSIONS,
  FOOTER_TEXT_PERMISSIONS2,
  HEADER_TEXT_PERMISSIONS,
} from './utils/constants/permissions';

import Home from './components/home/home';
import LoginContainer from './containers/Auth/LoginContainer';
import PermissionsContainer from './containers/PermissionsContainer';
import PasswordContainer from './containers/Auth/PasswordContainer';
import ResetPasswordContainer from './containers/Auth/ResetPasswordContainer';
import UsersContainer from './containers/UsersContainer';
import VisitorRegisterContainer from './containers/VisitorRegisterContainer';
import RolesContainer from './containers/RolesContainer';
import UserProfileContainer from './containers/Auth/UserProfileContainer';
import PaginasInstitucionalesContainer from './containers/PaginasInstitucionalesContainer';
import NewsPagesContainer from './containers/NewsPagesContainer';
import HeaderContainer from './containers/HeaderContainer';
import FootersContainer from './containers/FootersContainer';
import ContactFormContainer from './containers/ContactFormContainer';
import PortadasContainer from './containers/Portadas/PortadasContainer';
import ComponentesContainer from './containers/Portadas/ComponentesContainer';
import AgrupadoresContainer from './containers/FAQs/AgrupadoresContainer';
import FAQsContainer from './containers/FAQs/FAQsContainer';
import MenuUsrContainer from './containers/MenuUsrContainer';
import PanelUserContainer from './containers/PanelUserContainer';
import { ArchivosContainerList } from './containers/archivos/ArchivosContainerList';
import { ArchivosContainerAdd } from './containers/archivos/ArchivosContainerAdd';
import { ArchivosContainerEdit } from './containers/archivos/ArchivosContainerEdit';

export const routesApp = {
  inicio: { path: '/app/inicio', element: Home },
  users: { path: '/app/usuarios', element: UsersContainer, permission: USERS_PERMISSIONS.VIEW_USERS },
  addUser: { path: '/app/usuarios/agregar', element: UsersContainer, permission: USERS_PERMISSIONS.CREATE_USERS },
  editUser: { path: '/app/usuarios/editar/:userId', element: UsersContainer, permission: USERS_PERMISSIONS.EDIT_USERS },
  roles: { path: '/app/roles', element: RolesContainer, permission: ROLES_PERMISSIONS.VIEW_ROLES },
  addRol: { path: '/app/roles/agregar', element: RolesContainer, permission: ROLES_PERMISSIONS.CREATE_ROLES },
  editRol: { path: '/app/roles/editar/:rolId', element: RolesContainer, permission: ROLES_PERMISSIONS.EDIT_ROLES },
  permissions: { path: '/app/permisos', element: PermissionsContainer },
  profile: { path: '/app/perfil', element: UserProfileContainer },
  contactForm: { path: '/app/configuracion/form-contacto', element: ContactFormContainer },
  contactMessage: { path: '/app/configuracion/mensaje-contacto', element: ContactFormContainer, permission: CONTACT_PERMISSIONS.EDIT_MESSAGE },
  newsPage: {
    path: '/app/contenidos/paginas-noticias',
    element: NewsPagesContainer,
    permission: NEWS_PAGE_PERMISSIONS.VIEW_PAGINAS,
    notExact: true,
    children: {
      index: {
        path: '',
        permission: NEWS_PAGE_PERMISSIONS.VIEW_PAGINAS
      },
      add: {
        path: 'agregar',
        permission: NEWS_PAGE_PERMISSIONS.CREATE_PAGINAS
      },
      edit: {
        path: 'editar/:paginaId',
        permission: NEWS_PAGE_PERMISSIONS.EDIT_PAGINAS
      },
      preview: {
        path: 'previsualizar/:paginaId?',
      },
    }
  },
  paginasInstitucionales: { path: '/app/contenidos/paginas-institucionales', element: PaginasInstitucionalesContainer },
  addPaginasInstitucionales: { path: '/app/contenidos/paginas-institucionales/agregar', element: PaginasInstitucionalesContainer, permission: PAGINAS_INSTITUCIONALES_PERMISSIONS.CREATE_PAGINAS },
  editPaginasInstitucionales: { path: '/app/contenidos/paginas-institucionales/editar/:paginaId', element: PaginasInstitucionalesContainer, permission: PAGINAS_INSTITUCIONALES_PERMISSIONS.EDIT_PAGINAS },
  prevPaginasInstitucionales: { path: '/app/contenidos/paginas-institucionales/previsualizar/:paginaId?', element: PaginasInstitucionalesContainer },
  portadas: {
    path: '/app/contenidos/portadas',
    element: PortadasContainer,
    permission: PORTADAS_PERMISSIONS.VIEW_PORTADA,
    notExact: true,
    children: {
      index: {
        path: '',
        permission: PORTADAS_PERMISSIONS.VIEW_PORTADA
      },
      add: {
        path: 'agregar',
        permission: PORTADAS_PERMISSIONS.CREATE_PORTADA
      },
      edit: {
        path: 'editar/:portadaId',
        permission: PORTADAS_PERMISSIONS.EDIT_PORTADA
      },
    }
  },
  // portadas: { path: '/app/contenidos/portadas', element: PortadasContainer, permission: PORTADAS_PERMISSIONS.VIEW_PORTADA },
  addPortada: { path: '/app/contenidos/portadas/agregar', element: PortadasContainer, permission: PORTADAS_PERMISSIONS.CREATE_PORTADA },
  editPortada: { path: '/app/contenidos/portadas/editar/:portadaId', element: PortadasContainer, permission: PORTADAS_PERMISSIONS.EDIT_PORTADA },
  // previewPortada: { path: '/app/contenidos/portadas/previsualizar/:portadaId', element: PortadasContainer, permission: PORTADAS_PERMISSIONS.VIEW_PORTADA },
  componentes: { path: '/app/contenidos/portadas/:portadaId/componentes', element: ComponentesContainer, permission: COMPONENTES_PERMISSIONS.VIEW_COMPONENTE },
  addComponente: { path: '/app/contenidos/portadas/:portadaId/componentes/agregar', element: ComponentesContainer, permission: COMPONENTES_PERMISSIONS.CREATE_COMPONENTE },
  editComponente: { path: '/app/contenidos/portadas/:portadaId/componentes/editar/:componenteId', element: ComponentesContainer, permission: COMPONENTES_PERMISSIONS.EDIT_COMPONENTE },
  agrupadores: { path: '/app/configuracion/faqs/agrupadores', element: AgrupadoresContainer, permission: AGRUPADORES_PERMISSIONS.VIEW_AGRUPADOR },
  addAgrupador: { path: '/app/configuracion/faqs/agrupadores/agregar', element: AgrupadoresContainer, permission: AGRUPADORES_PERMISSIONS.CREATE_AGRUPADOR },
  editAgrupador: { path: '/app/configuracion/faqs/agrupadores/editar/:agrupadorId', element: AgrupadoresContainer, permission: AGRUPADORES_PERMISSIONS.EDIT_AGRUPADOR },
  faqs: { path: '/app/configuracion/faqs/agrupadores/:agrupadorId/faqs', element: FAQsContainer, permission: FAQS_PERMISSIONS.VIEW_FAQ },
  addFaq: { path: '/app/configuracion/faqs/agrupadores/:agrupadorId/faqs/agregar', element: FAQsContainer, permission: FAQS_PERMISSIONS.CREATE_FAQ },
  editFaq: { path: '/app/configuracion/faqs/agrupadores/:agrupadorId/faqs/editar/:faqId', element: FAQsContainer, permission: FAQS_PERMISSIONS.EDIT_FAQ },
  headers: { path: '/app/configuracion/encabezado/menu-navegacion', element: HeaderContainer, permission: MENU_PERMISSIONS.VIEW_MENU },
  addHeader: { path: '/app/configuracion/encabezado/menu-navegacion/agregar', element: HeaderContainer, permission: MENU_PERMISSIONS.CREATE_MENU },
  editHeader: { path: '/app/configuracion/encabezado/menu-navegacion/editar/:headerId', element: HeaderContainer, permission: MENU_PERMISSIONS.EDIT_MENU },
  logoHeader: { path: '/app/configuracion/encabezado/logo', element: HeaderContainer, permission: LOGOS_PERMISSIONS.VIEW_LOGO },
  headerText: { path: '/app/configuracion/encabezado/texto', element: HeaderContainer, permission: HEADER_TEXT_PERMISSIONS.VIEW_HEADER_TEXT },
  contactFormText: { path: '/app/configuracion/formulario-contacto', element: ContactFormContainer, permission: CONTACT_PERMISSIONS.VIEW_CONTACT },
  footerText: { path: '/app/configuracion/pie-pagina/texto', element: FootersContainer, permission: FOOTER_TEXT_PERMISSIONS.VIEW_FOOTER_TEXT },
  footerText2: { path: '/app/configuracion/pie-pagina/texto2', element: FootersContainer, permission: FOOTER_TEXT_PERMISSIONS2.VIEW_FOOTER_TEXT },
  logoFooter: { path: '/app/configuracion/pie-pagina/logo', element: FootersContainer, permission: LOGOS_PERMISSIONS.VIEW_LOGO },
  logoFooterDerecha: { path: '/app/configuracion/pie-pagina/logo-centro', element: FootersContainer, permission: LOGOS_PERMISSIONS.VIEW_LOGO },
  footers: { path: '/app/configuracion/pie-pagina/menu-navegacion', element: FootersContainer, permission: MENU_PERMISSIONS.VIEW_MENU },
  addFooter: { path: '/app/configuracion/pie-pagina/menu-navegacion/agregar', element: FootersContainer, permission: MENU_PERMISSIONS.CREATE_MENU },
  editFooter: { path: '/app/configuracion/pie-pagina/menu-navegacion/editar/:footerId', element: FootersContainer, permission: MENU_PERMISSIONS.EDIT_MENU },
  registroVisitas: { path: '/app/registro-visitas', element: VisitorRegisterContainer, permission: VISITOR_REGISTER_PERMISSIONS.VIEW_VISITOR_REGISTER },
  menuUsr: { path: '/app/configuracion/menu-usuario', element: MenuUsrContainer, permission: MENU_PERMISSIONS.VIEW_MENU },
  addMenuUsr: { path: '/app/configuracion/menu-usuario/agregar', element: MenuUsrContainer, permission: MENU_PERMISSIONS.CREATE_MENU },
  editMenuUsr: { path: '/app/configuracion/menu-usuario/editar/:menuUsrId', element: MenuUsrContainer, permission: MENU_PERMISSIONS.EDIT_MENU },
  panelUser: { path: '/app/configuracion/panel-usuario', element: PanelUserContainer, permission: PANEL_USER_PERMISSIONS.VIEW_PANEL_USER },
  addPanelUser: { path: '/app/configuracion/panel-usuario/agregar', element: PanelUserContainer, permission: PANEL_USER_PERMISSIONS.CREATE_PANEL_USER },
  editPanelUser: { path: '/app/configuracion/panel-usuario/editar/:panelUserId', element: PanelUserContainer, permission: PANEL_USER_PERMISSIONS.EDIT_PANEL_USER },

  // Upload routes
  files: { path: '/app/configuracion/archivos', element: ArchivosContainerList, permission: UPLOAD_PERMISSIONS.VIEW_UPLOAD },
  addFile: { path: '/app/configuracion/archivos/agregar', element: ArchivosContainerAdd, permission: UPLOAD_PERMISSIONS.CREATE_UPLOAD },
  editFile: { path: '/app/configuracion/archivos/editar/:uploadId', element: ArchivosContainerEdit, permission: UPLOAD_PERMISSIONS.EDIT_UPLOAD },

  // error: { path: '/app/*', element: () => <Redirect to="/404" /> }
  error: { path: '/app/*', element: (props) => { console.log('fucking failure', props); return <></> } }
};

export const routes = {
  login: { path: '/login', element: LoginContainer },
  resetPassword: { path: '/restaurar-contraseña', element: PasswordContainer },
  changePassword: { path: '/cambiar-contraseña/:hash/:email', element: ResetPasswordContainer },
  sitemap: { path: '/sitemap.xml', element: () => <Route exact path="/sitemap.xml" render={() => { (window.location = `${process.env.REACT_APP_BASE_URL_API}/sitemap.xml`) }} /> },
  404: { path: '/404', element: NotFoundView },
  403: { path: '/403', element: ForbiddenView },
  general: { path: '/', element: () => <Redirect to="/app/inicio" /> },
  error: { path: '/*', element: () => <Redirect to="/404" /> },
}
