import React from 'react';
// import { getCookie } from 'src/helpers/random';
import { validateOnePermission } from 'src/helpers/permissions';
import { CKEDITOR_PERMISSIONS } from 'src/utils/constants/permissions';
import CKEditorJuanaManso from '../CkeditorJuanaManso';

const CKEditorFull = (props) => {
  const sourceHTML = { name: 'source', items: ['Source'] };

  const configCkeditorFull = {
    // height: 500,
    toolbar: [
      { name: 'heading', items: ['Format'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'colour', items: ['TextColor', 'BGColor'] },
      { name: 'styles', items: ['Styles', 'Font', 'FontSize'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      '/',
      { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
      { name: 'insert', items: ['SImage', 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
      { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
      { name: 'editing', items: ['Find', 'Replace', 'SelectAll', '-', 'Scayt', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      { name: 'document', items: ['Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', 'Templates'] },
      { name: 'embed', items: ['Html5audio', 'Html5video'] },
      // validateOnePermission(props.permissions, CKEDITOR_PERMISSIONS.VIEW_SOURCE) ? sourceHTML : {}
      { name: 'source', items: ['Source'] }
    ],
/*     ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: `${process.env.REACT_APP_BASE_URL_API}/ckfinder/connector?command=QuickUpload&type=Images&responseType=json`,
      options: {
        resourceType: 'Images',
        connectorPath: `${process.env.REACT_APP_BASE_URL_API}/ckfinder/connector`,
        language: 'es',
      },
      headers: {
        'X-CSRF-TOKEN': getCookie('ckCsrfToken'),
      },
      connectorPath: `${process.env.REACT_APP_BASE_URL_API}/ckfinder/connector`
    }, */
  };

  return (
    <div>
      <CKEditorJuanaManso
        configParent={configCkeditorFull}
        {...props}
      />
    </div>
  );
};

export default CKEditorFull;
