import React, { useEffect, useState } from 'react'
import {
  Card, CardContent, Typography
} from '@material-ui/core'

import { FORM_NAMES } from 'src/utils/constants/general'
import Loading from 'src/components/shared/loading'
import PageContainer from 'src/containers/shared/PageContainer'
import ErrorsValidation from 'src/components/shared/displayErrorsValidations'

import FormPortada from './shared/FormPortada'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PortadasService from 'src/services/PortadasService'

const PortadaView = (props) => {
  const { action, errors, loading, portadas, match: { params }, ...rest } = props
  const formTitle = FORM_NAMES[action];
  const [portada, setPortada] = useState(null);
  const [allPortadas, setAllPortadas] = useState(null);
  const { portadaId } = useParams();

  useEffect(() => {
    PortadasService.getPortadas(1, 100, '')
      .then(res => setAllPortadas(res.data.data))
  }, [])

  useEffect( () => {
    if(portadaId) {
      PortadasService.getOnePortada(portadaId)
        .then(res => setPortada(res.data))
    } else { setPortada({}) }
  }, [portadaId])


  if (!portada || !allPortadas) {
    return <Loading />
  }

  return (
    <PageContainer title={`${formTitle} Portada`} maxWidth={false}>
      <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
      >
        {`${formTitle.replace(/.$/, 'ndo')} Portada`}
      </Typography>
      <ErrorsValidation errors={errors} />
      <Card>
        <CardContent>
          <FormPortada
            portada={portada}
            onSubmit={rest.onSubmitCallback(params?.portadaId)}
            loading={loading}
            allPortadas={allPortadas}
          />
        </CardContent>
      </Card>
    </PageContainer>
  )
}

export default PortadaView
